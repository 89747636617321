import React, { useEffect } from "react";
import styled from "styled-components/macro";

import { Link } from "../components/text";
import Gap from "../components/Gap";

import { ReactComponent as HomeLogo } from "../assets/imgs/HomeLogo.svg";
import { useHistory } from "react-router-dom";
import { gql } from "graphql-tag";
import { useQuery } from "@apollo/client";
import { initWeb3 } from "../web3modal";
import { CTAButton } from "../components/buttons";
import { Trans } from "@lingui/macro";
import { logEvent } from "../utils/analytics";

const HomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const WrappedTitle = styled.div`
  font-weight: bold;
  font-size: 44px;
  line-height: 118%;

  text-align: center;
  letter-spacing: -0.01em;
  background: #ffffff;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const WrappedSubTitle = styled.div`
  max-width: 560px;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 150%;

  text-align: center;
  letter-spacing: -0.01em;

  color: #ffffff;
`;

const HOME_QUERY = gql`
  query privateRouteQuery @client {
    addressDetails
    address
  }
`;

const HomeSwap = () => {
  const {
    data: { address },
  } = useQuery(HOME_QUERY);
  const history = useHistory();

  const handleClick = () => {
    if (address) {
      logEvent("get_started");
      history.push("/dashboard");
    } else {
      logEvent("connect_wallet");
      initWeb3();
    }
  };

  useEffect(() => {
    logEvent("home_page");
  }, []);

  return (
    <HomeContainer>
      <HomeLogo style={{ marginBottom: "60px" }} />
      <WrappedTitle>
        <Trans>HIFI Token Swap</Trans>
      </WrappedTitle>
      <Gap height={3} />
      <WrappedSubTitle>
        <Trans>
          HIFI token gives you influence to decide the future of Hifi DAO and
          control over the Hifi protocol through governance.
        </Trans>
      </WrappedSubTitle>
      <Gap height={3} />
      <Link
        href="https://blog.hifi.finance/hifi-tokenomics-lite-paper-9bc0bb9fa36e"
        target="_blank"
      >
        <Trans>Introducing $HIFI →</Trans>
      </Link>
      <Gap height={8} />
      <CTAButton
        text={
          address ? <Trans>Start Swap</Trans> : <Trans>Connect Wallet</Trans>
        }
        onClick={handleClick}
      />
    </HomeContainer>
  );
};

export default HomeSwap;
