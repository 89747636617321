import Delegate from "../assets/imgs/Delegate.png";
import { initWeb3 } from "../web3modal";
import { logEvent } from "../utils/analytics";
import { useQuery } from "@apollo/client";
import { gql } from "graphql-tag";
import React from "react";
import styled from "styled-components";
import { CTAButton } from "../components/buttons";
import Gap from "../components/Gap";
import { Trans } from "@lingui/macro";

const BlockImage = styled.img`
  width: 100%;
  height: auto;
  max-width: 415px;
  margin: 30px;
`;

const BlockContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const Block = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 500px;
  text-align: center;
`;

const WrappedTitle = styled.div`
  font-weight: bold;
  font-size: 44px;
  line-height: 118%;

  text-align: center;
  letter-spacing: -0.01em;
  background: #ffffff;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const WrappedSubTitle = styled.div`
  margin-top: 10px;
  margin-bottom: 30px;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 150%;
  letter-spacing: -0.01em;
  color: #ffffff;

  @media only screen and (max-width: 400px) {
    font-size: 16px;
  }
`;

const HOME_QUERY = gql`
  query privateRouteQuery @client {
    addressDetails
    address
  }
`;

const TokenDelegation = ({ onClick }) => {
  const {
    data: { address },
  } = useQuery(HOME_QUERY);

  const handleClick = () => {
    if (address) {
      onClick && onClick();
    } else {
      logEvent("connect_wallet");
      initWeb3();
    }
  };

  return (
    <BlockContainer>
      <Block>
        <BlockImage src={Delegate} />
        <WrappedTitle>
          <Trans>HIFI Token Delegation</Trans>
        </WrappedTitle>
        <WrappedSubTitle>
          <Trans>
            Delegate $HIFI token voting power to represent you when voting on
            Hifi DAO proposals.
          </Trans>
        </WrappedSubTitle>
        <CTAButton
          text={
            address ? (
              <Trans>Delegate HIFI</Trans>
            ) : (
              <Trans>Connect Wallet</Trans>
            )
          }
          onClick={handleClick}
        />
      </Block>
    </BlockContainer>
  );
};

export default TokenDelegation;
