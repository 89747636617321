import styled from "styled-components";

const hamburgerBarHeight = 2;
export const hamburgerBarSpacing = 4;

const Container = styled.div`
  width: 100%;
  height: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
`;

const Inner = styled.span`
  display: block;
  margin-top: ${hamburgerBarHeight / -2}px;
  transition-duration: 0.075s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  &,
  &::before,
  &::after {
    width: 100%;
    height: ${hamburgerBarHeight}px;
    background-color: white;
    border-radius: 0.5rem;
    position: absolute;
  }
  &::before,
  &::after {
    content: "";
    display: block;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
  }
  &::before {
    top: ${-hamburgerBarSpacing - hamburgerBarHeight}px;
    transition: top 0.075s 0.12s ease, opacity 0.075s ease;
  }
  &::after {
    bottom: ${-hamburgerBarSpacing - hamburgerBarHeight}px;
    transition: bottom 0.075s 0.12s ease,
      transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  ${({ active }) =>
    active &&
    `
    transform: rotate(45deg);
    transition-delay: 0.12s;
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    &::before {
        top: 0;
        opacity: 0;
        transition: top 0.075s ease, opacity 0.075s 0.12s ease;
    }
    &::after {
        bottom: 0;
        transform: rotate(-90deg);
        transition: bottom 0.075s ease,
        transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    `}
`;
// TODO: collapse on url click
export function Hamburger({ active, onClick }) {
  return (
    <Container onClick={onClick}>
      <Inner active={active} />
    </Container>
  );
}
