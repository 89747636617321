import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { gql } from "graphql-tag";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { utils } from "ethers";
import { Content } from "../components/text";
import { Trans } from "@lingui/macro";

import {
  ContentBox,
  InnerContentBox,
  NarrowColumn,
} from "../components/layout";
import {
  DecimalBalance,
  Header,
  IntegerBalance,
  Statistic,
  SubsubTitle,
} from "../components/text";
import Gap from "../components/Gap";
import { getEthersProvider } from "../web3modal";
import { imageUrl, shortenAddress } from "../utils/utils";
import { CTAButton } from "../components/buttons";
import { ReactComponent as SplashHifiLogo } from "../assets/imgs/SplashHifiLogo.svg";
import { ReactComponent as SplashMainframeLogo } from "../assets/imgs/SplashMainframeLogo.svg";
import { getDelegateChoice } from "./ENSConstitution/delegateHelpers";
import Pill from "../components/Pill";
import { logEvent } from "../utils/analytics";

const HifiLogo = styled(SplashHifiLogo)`
  width: 40px;
  height: 40px;
  margin-left: 10px;
`;
const MainframeLogo = styled(SplashMainframeLogo)`
  width: 40px;
  height: 40px;
  margin-left: 10px;
`;

const AvatarImg = styled.img`
  border-radius: 50%;
  width: ${(p) => (p.large ? "60px" : "50px")};
  height: ${(p) => (p.large ? "60px" : "50px")};
  margin-right: 10px;
`;

const WrappedInnerContentBox = styled(InnerContentBox)`
  display: flex;
`;

const LeftContainer = styled.div`
  flex: 1;
  width: calc(100% - 60px);
`;

const RightContainer = styled.div`
  display: flex;
  align-items: center;
`;

const DelegateInfoContainer = styled.div`
  display: flex;
  align-items: center;
`;

const DelegateName = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 141%;
  color: #000000;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const DelegateConfirmation = ({ isDelegateValid, setIsDelegateValid }) => {
  const {
    data: { isConnected, address },
  } = useQuery(gql`
    query privateRouteQuery @client {
      isConnected
      address
    }
  `);
  const [delegateInfo, setDelegateInfo] = useState({
    avatar: "",
    displayName: "",
  });
  const history = useHistory();

  // useEffect(() => {
  //   const run = async () => {
  //     const delegateChoice = getDelegateChoice(address);

  //     if (!delegateChoice) {
  //       console.error("No delegate selected");
  //       return;
  //     }

  //     if (delegateChoice.includes(".")) {
  //       const resolver = await getEthersProvider().getResolver(delegateChoice);
  //       try {
  //         const addr = await resolver.getAddress(60);
  //         utils.getAddress(addr);
  //         const avatar = await resolver.getText("avatar");
  //         setDelegateInfo({
  //           avatar,
  //           displayName: delegateChoice,
  //         });
  //         setIsDelegateValid(true);
  //       } catch (e) {
  //         setIsDelegateValid(false);
  //       }
  //       return;
  //     }

  //     const ethName = await getEthersProvider().lookupAddress(delegateChoice);
  //     if (ethName) {
  //       const resolver = await getEthersProvider().getResolver(ethName);
  //       const avatar = await resolver.getText("avatar");
  //       setDelegateInfo({
  //         avatar,
  //         displayName: ethName,
  //       });
  //       setIsDelegateValid(true);
  //       return;
  //     }

  //     setDelegateInfo({
  //       avatar: null,
  //       address: delegateChoice,
  //     });
  //     setIsDelegateValid(true);
  //   };
  //   if (isConnected) {
  //     run();
  //   }
  // }, [isConnected]);

  return (
    <></>
    // <WrappedInnerContentBox>
    //   <LeftContainer>
    //     <SubsubTitle>You're delegating to</SubsubTitle>
    //     <Gap height={2} />
    //     <DelegateInfoContainer>
    //       {delegateInfo.avatar && (
    //         <AvatarImg
    //           src={imageUrl(delegateInfo.avatar, delegateInfo.displayName, 1)}
    //         />
    //       )}
    //       <DelegateName>
    //         {delegateInfo.displayName
    //           ? delegateInfo.displayName
    //           : shortenAddress(delegateInfo.address)}
    //       </DelegateName>
    //     </DelegateInfoContainer>
    //   </LeftContainer>
    //   <RightContainer>
    //     <CTAButton
    //       onClick={() => {
    //         history.push("/delegates");
    //       }}
    //       type={"deny"}
    //       text={"Edit"}
    //     />
    //   </RightContainer>
    // </WrappedInnerContentBox>
  );
};

const HifiSummary = () => {
  const {
    data: { addressDetails },
  } = useQuery(gql`
    query privateRouteQuery @client {
      address
      addressDetails
      isConnected
    }
  `);
  // const [isDelegateValid, setIsDelegateValid] = useState(true);
  const history = useHistory();

  const { hifiSwapBalance, mftBalance, hasApproval } = addressDetails;

  useEffect(() => {
    logEvent("summary_page");
  }, []);

  return (
    <NarrowColumn>
      {/* {isDelegateValid === false ? (
        <Pill error text={"Delegate is invalid, click edit to pick another"} />
      ) : (
        <Pill
          text={
            isDelegateValid === undefined
              ? "Checking delegate validity"
              : "Delegate is valid!"
          }
        />
      )} */}
      <ContentBox>
        <Header>
          <Trans>Review your swap</Trans>
        </Header>
        <Gap height={3} />
        <Content>
          <Trans>Please make sure the following details look correct.</Trans>
        </Content>
        <Gap height={5} />
        <InnerContentBox>
          <SubsubTitle>
            <Trans>You currently have</Trans>
          </SubsubTitle>
          <Gap height={1} />
          <Statistic>
            <IntegerBalance>{mftBalance?.split(".")[0]}</IntegerBalance>
            <DecimalBalance>.{mftBalance?.split(".")[1]}</DecimalBalance>
            <MainframeLogo />
          </Statistic>
        </InnerContentBox>
        <Gap height={2} />
        <InnerContentBox>
          <SubsubTitle>
            <Trans>You will receive</Trans>
          </SubsubTitle>
          <Gap height={1} />
          <Statistic>
            <IntegerBalance>{hifiSwapBalance?.split(".")[0]}</IntegerBalance>
            <DecimalBalance>.{hifiSwapBalance?.split(".")[1]}</DecimalBalance>
            <HifiLogo />
          </Statistic>
        </InnerContentBox>
        <Gap height={3} />
        {/* <DelegateConfirmation
          isDelegateValid={isDelegateValid}
          setIsDelegateValid={setIsDelegateValid}
        /> */}
        <Gap height={5} />
        <CTAButton
          onClick={() => {
            hasApproval
              ? history.push({
                  pathname: "/summary/swap",
                  state: "SWAP",
                })
              : history.push({
                  pathname: "/summary/approve",
                  state: "APPROVE",
                });
          }}
          text={
            // isDelegateValid === undefined
            //   ? "Checking delegate validity..."
            //   :
            hasApproval ? <Trans>Swap</Trans> : <Trans>Approve MFT</Trans>
          }
          // type={isDelegateValid ? "" : "disabled"}
        />
      </ContentBox>
    </NarrowColumn>
  );
};

export default HifiSummary;
