export function init() {
  if (window.firebase) {
    window.firebase.analytics();
  }
}

export function logEvent(name, properties) {
  if (window.firebase) {
    if (typeof properties === "object") {
      Object.keys(properties).forEach((prop) => {
        const val = Object(properties)[prop];
        if (typeof val === "string" && val.indexOf("0x") === 0) {
          Object(properties)[prop] = val.substr(2);
        }
      });
      window.firebase.analytics().logEvent(name, properties);
    } else {
      window.firebase.analytics().logEvent(name);
    }
  }
}
