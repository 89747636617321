import { NavLink } from "react-router-dom";
import { Trans } from "@lingui/macro";
import styled from "styled-components";

import logoMini from "../assets/imgs/logo-mini.svg";
import config from "../config.json";
import {
  MEDIA_SIZES,
  contentWidthMin,
  footerHeight,
  maxWidthMax,
  sideSpacing,
} from "../styles";

const Container = styled.div`
  display: grid;
  position: relative;
  width: 100%;
  margin: 0px auto;
  max-width: ${maxWidthMax};
  justify-content: center;
  height: ${footerHeight};
  box-sizing: border-box;
  padding: 9rem 0px;
  grid-template-columns:
    minmax(auto, ${sideSpacing.max}) [footer-content] minmax(
      ${contentWidthMin},
      100%
    )
    minmax(auto, ${sideSpacing.max});
  @media only screen and (${MEDIA_SIZES.max.desktopXL}) {
    height: auto;
  }
  @media only screen and (${MEDIA_SIZES.max.desktopL}) {
    padding: 4.6875rem 0px;
    grid-template-columns:
      minmax(auto, ${sideSpacing.xxxlarge}) [footer-content] minmax(
        ${contentWidthMin},
        100%
      )
      minmax(auto, ${sideSpacing.xxxlarge});
  }
  @media only screen and (${MEDIA_SIZES.max.desktopS}) {
    grid-template-columns:
      minmax(auto, ${sideSpacing.xxlarge}) [footer-content] minmax(
        ${contentWidthMin},
        100%
      )
      minmax(auto, ${sideSpacing.xxlarge});
  }
  @media only screen and (${MEDIA_SIZES.max.tablet}) {
    grid-template-columns:
      minmax(auto, ${sideSpacing.xlarge}) [footer-content] minmax(
        ${contentWidthMin},
        100%
      )
      minmax(auto, ${sideSpacing.xlarge});
  }
  @media only screen and (${MEDIA_SIZES.max.phoneL}) {
    grid-template-columns:
      minmax(auto, ${sideSpacing.large}) [footer-content] minmax(
        ${contentWidthMin},
        100%
      )
      minmax(auto, ${sideSpacing.large});
  }
  @media only screen and (${MEDIA_SIZES.max.phoneM}) {
    grid-template-columns:
      minmax(auto, ${sideSpacing.medium}) [footer-content] minmax(
        ${contentWidthMin},
        100%
      )
      minmax(auto, ${sideSpacing.medium});
  }
`;

const Content = styled.footer`
  background-image: url(${logoMini});
  background-size: 2.3rem;
  background-repeat: no-repeat;
  grid-column: footer-content;
  margin-left: -40px;
  @media only screen and (${MEDIA_SIZES.max.phoneL}) {
    margin-left: 0px;
  }
`;
// TODO: fix to content margins + max width for each view
const LinkGroupContainer = styled.div`
  display: grid;
  margin-top: 0.75rem;
  margin-left: 12.1875rem;
  @media only screen and (${MEDIA_SIZES.max.desktopL}) {
    margin-top: 6.75rem;
    margin-left: 0px;
  }
  max-width: 648px;
  grid-template-columns: repeat(auto-fit, 3rem);
  grid-column-gap: 36px;
  grid-row-gap: 90px;
  justify-content: space-between;
  @media only screen and (${MEDIA_SIZES.max.phoneL}) {
    justify-content: left;
    max-width: 38rem;
    grid-row-gap: 65px;
    grid-column-gap: max(3rem, 25%);
  }
`;

const LinkContainer = styled.div`
  text-align: left;
`;

const LinkTitle = styled.div`
  font-weight: 600;
  font-size: 18px;
  color: white;
  @media only screen and (${MEDIA_SIZES.max.desktopS}) {
    font-size: 15px;
  }
`;

const LinkColumn = styled.div`
  position: relative;
  top: 1.4rem;
`;

const FooterLinkStyle = `
  display: block;
  text-decoration: none;
  font-weight: normal;
  font-size: 16px;
  white-space: nowrap;

  @media only screen and (${MEDIA_SIZES.max.desktopS}) {
    font-size: 13px;
  }

  margin-bottom: 1.5rem;
  color: #f0f0f0;
  cursor: pointer;
  &:hover {
    color: #794df6;
  }
`;

const FooterNavLink = styled(NavLink)`
  ${FooterLinkStyle}
`;

const FooterALink = styled.a`
  ${FooterLinkStyle}
`;

function FooterLink({ children, to, event, internal = false, isBlank = true }) {
  return (
    <div>
      {internal ? (
        <FooterNavLink
          to={to}
          onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
        >
          {children}
        </FooterNavLink>
      ) : (
        <FooterALink
          href={to}
          target={isBlank ? "_blank" : "_self"}
          rel="noreferrer"
        >
          {children}
        </FooterALink>
      )}
    </div>
  );
}

const Copyright = styled.div`
  position: absolute;
  margin-top: -0.9375rem;
  right: ${sideSpacing.max};
  font-weight: normal;
  max-width: 14rem;
  font-size: 1rem;
  color: white;
  @media only screen and (${MEDIA_SIZES.max.phoneL}) {
    font-size: 0.9rem;
  }
  @media only screen and (${MEDIA_SIZES.max.desktopXL}) {
    position: relative;
    right: auto;
    margin-left: 0px;
    margin-top: 5.625rem;
  }
`;

function FooterLinkGroup({ isReleased }) {
  return (
    <LinkGroupContainer>
      <LinkContainer>
        <LinkTitle>
          <Trans>Hifi</Trans>
        </LinkTitle>
        <LinkColumn>
          <FooterLink event="footer_link_tokenswap" to={config.swapUrl}>
            <Trans>Token Swap</Trans>
          </FooterLink>
          <FooterLink to={"https://hifi.finance/ecosystem"} isBlank={false}>
            <Trans>Ecosystem</Trans>
          </FooterLink>
          <FooterLink to={"https://hifi.finance/privacy"} isBlank={false}>
            <Trans>Privacy</Trans>
          </FooterLink>
          <FooterLink to={"https://hifi.finance/terms"} isBlank={false}>
            <Trans>Terms</Trans>
          </FooterLink>
        </LinkColumn>
      </LinkContainer>
      <LinkContainer>
        <LinkTitle>
          <Trans>Community</Trans>
        </LinkTitle>
        <LinkColumn>
          <FooterLink event="footer_link_discord" to={config.discordUrl}>
            <Trans>Discord</Trans>
          </FooterLink>
          <FooterLink event="footer_link_blog" to={config.blogUrl}>
            <Trans>Blog</Trans>
          </FooterLink>
          <FooterLink event="footer_link_twitters" to={config.twitterUrl}>
            <Trans>Twitter</Trans>
          </FooterLink>
          <FooterLink to={"/branding"} internal>
            <Trans>Branding</Trans>
          </FooterLink>
        </LinkColumn>
      </LinkContainer>
      <LinkContainer>
        <LinkTitle>
          <Trans>Protocol</Trans>
        </LinkTitle>
        <LinkColumn>
          {isReleased && (
            <FooterLink event="footer_link_app" to={config.appUrl}>
              <Trans>Lend/Borrow</Trans>
            </FooterLink>
          )}
          {isReleased && (
            <FooterLink event="footer_link_docs" to={config.docsUrl}>
              <Trans>Docs</Trans>
            </FooterLink>
          )}
          <FooterLink event="footer_link_github" to={config.githubUrl}>
            <Trans>Github</Trans>
          </FooterLink>
        </LinkColumn>
      </LinkContainer>
      <LinkContainer>
        <LinkTitle>
          <Trans>Ecosystem</Trans>
        </LinkTitle>
        <LinkColumn>
          <FooterLink event="footer_link_" to={config.pooledNFTUrl}>
            <Trans>PooledNFT</Trans>
          </FooterLink>
          <FooterLink
            event="footer_link_crownribbon"
            to={config.crownRibbonUrl}
          >
            <Trans>CrownRibbon</Trans>
          </FooterLink>
          <FooterLink event="footer_link_github" to={config.sheetheadsUrl}>
            <Trans>SheetHeads</Trans>
          </FooterLink>
          {isReleased && (
            <FooterLink event="footer_link_docs" to={config.sablierUrl}>
              <Trans>Sablier</Trans>
            </FooterLink>
          )}
        </LinkColumn>
      </LinkContainer>
    </LinkGroupContainer>
  );
}

export function Footer({ isReleased }) {
  return (
    <Container>
      <Content>
        <FooterLinkGroup isReleased={isReleased} />
        <Copyright>© {new Date().getFullYear()} Hifi.Finance</Copyright>
      </Content>
    </Container>
  );
}
