import React, { useEffect, useState } from "react";
import { gql } from "graphql-tag";
import { useQuery } from "@apollo/client";

import Footer from "../components/Footer";
import { Content, Header } from "../components/text";
import { ContentBox, NarrowColumn } from "../components/layout";
import Gap from "../components/Gap";
import { useHistory } from "react-router-dom";
import TransactionState from "../components/TransactionState";
import Pill from "../components/Pill";
import { handleApprove } from "../utils/token";
import { Trans } from "@lingui/macro";
import { logEvent } from "../utils/analytics";

const getRightButtonText = (state) => {
  switch (state) {
    case "LOADING":
      return <Trans>Approving...</Trans>;
    case "SUCCESS":
      return <Trans>Continuing...</Trans>;
    case "ERROR":
      return <Trans>Try again</Trans>;
  }
};

const HifiTokenApprove = ({ location }) => {
  const {
    data: { isConnected, address, addressDetails },
  } = useQuery(gql`
    query privateRouteQuery @client {
      isConnected
      address
      addressDetails
    }
  `);
  const history = useHistory();
  if (history.action === "POP") {
    history.push("/");
  }
  const [approveState, setApproveState] = useState({
    state: "LOADING",
    message: "",
  });

  useEffect(() => {
    let timeout;
    const run = async () => {
      if (
        location.state === "APPROVE" &&
        isConnected &&
        addressDetails.rawHifiSwapBalance
      ) {
        timeout = await handleApprove(setApproveState, history);
      }
    };

    run();
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [isConnected]);

  useEffect(() => {
    logEvent("token_approve_page");
  }, []);

  return (
    <NarrowColumn>
      {approveState.state === "ERROR" && (
        <Pill error text={approveState.message.message} />
      )}
      <ContentBox>
        <Header>
          <Trans>Confirm with wallet</Trans>
        </Header>
        <Gap height={3} />
        <Content>
          <Trans>Please submit the transaction to approve your tokens.</Trans>
        </Content>
        <Gap height={6} />
        <TransactionState
          transactionState={approveState.state}
          title={<Trans>Approve MFT</Trans>}
          content={
            <Trans>
              This transaction happens on-chain, and will require paying gas
            </Trans>
          }
        />
      </ContentBox>
      <Footer
        leftButtonText={<Trans>Back</Trans>}
        leftButtonCallback={() => {
          history.push("/summary");
        }}
        rightButtonText={getRightButtonText(approveState.state)}
        rightButtonCallback={() => {
          if (approveState.state === "SUCCESS") {
            history.push({
              pathname: "/summary/swap",
              state: "SWAP",
            });
            return;
          }
          handleApprove(setApproveState, history);
        }}
        disabled={approveState.state === "LOADING" ? "disabled" : ""}
      />
    </NarrowColumn>
  );
};

export default HifiTokenApprove;
