import { gql, useQuery } from "@apollo/client";
import { utils } from "ethers";
import debounce from "lodash.debounce";
import React, { useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import styled from "styled-components/macro";
import { selectedDelegateReactive } from "../apollo";
import Footer from "../components/Footer";
import Gap from "../components/Gap";
import { ContentBox, NarrowColumn } from "../components/layout";
import { Content, Header } from "../components/text";
import { useGetDelegateBySigStatus, useGetDelegatedTo } from "../utils/hooks";
import { getMainnetEthersProvider } from "../web3modal";
import { getDelegateChoice } from "./ENSConstitution/delegateHelpers";
import { Trans } from "@lingui/macro";
import { logEvent } from "../utils/analytics";

const Input = styled.input`
  height: 64px;
  width: 100%;
  -webkit-appearance: none;
  outline: none;
  border: 1px solid rgb(54, 54, 54);
  background: #252525;
  border-radius: 14px;
  padding: 0px 20px;
  box-sizing: border-box;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 28px;
  margin-bottom: 12px;

  &::placeholder {
    color: #ffffff;
    opacity: 0.23;
  }

  color: #ffffff;
`;

const ValidationMessageContainer = styled.div`
  background: ${(p) =>
    p.error ? "rgba(213, 85, 85, 0.1)" : "rgba(73, 179, 147, 0.1)"};
  border-radius: 10px;
  padding: 10px 14px;
  box-sizing: border-box;

  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;

  display: flex;
  align-items: center;

  color: ${(p) => (p.error ? "#D55555" : "#49B393")};
`;

const ValidationMessage = (props) => {
  return (
    <ValidationMessageContainer error={props.error}>
      {props.children}
    </ValidationMessageContainer>
  );
};

const AddressMessage = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 19px;

  color: #989898;
  margin-bottom: 14px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const InputComponent = ({
  validationMessage,
  setValidationMessage,
  ensNameAddress,
  setEnsNameAddress,
  setValue,
  delegatedTo,
  defaultValue,
  ...props
}) => {
  const onChange = (event) => {
    const value = event.target.value;
    setValue(value);

    const run = async () => {
      if (value.includes(".")) {
        try {
          const result = await getMainnetEthersProvider().resolveName(value);
          if (result) {
            setEnsNameAddress(result);
            if (result === delegatedTo) {
              setValidationMessage({
                message: "Your tokens are already delegated to this address",
                isError: true,
              });
              return;
            }
            setValidationMessage({
              message: "Valid ENS name",
              isError: false,
            });
            return;
          }
          throw "error";
        } catch (error) {
          setValidationMessage({
            message: "ENS name does not resolve to an ETH address",
            isError: true,
          });
        }
        setEnsNameAddress("");
        return;
      }

      try {
        utils.getAddress(value);
        if (value === delegatedTo) {
          setValidationMessage({
            message: "Your tokens are already delegated to this address",
            isError: true,
          });
          return;
        }
        setValidationMessage({
          message: "Valid Ethereum address",
          isError: false,
        });
      } catch (error) {
        setValidationMessage({
          message: "Invalid ethereum address",
          isError: true,
        });
      }
      setEnsNameAddress("");
    };

    if (value) {
      setEnsNameAddress("");
      run();
    } else {
      setValidationMessage({
        message: "",
        isError: false,
      });
    }
  };

  useEffect(() => {
    if (defaultValue) {
      onChange({
        target: {
          value: defaultValue,
        },
      });
    }
  }, [defaultValue]);

  return (
    <div>
      <Input
        {...props}
        onChange={debounce(onChange, 100)}
        defaultValue={defaultValue}
      />
      {ensNameAddress && <AddressMessage>{ensNameAddress}</AddressMessage>}
      {validationMessage.message && (
        <ValidationMessage error={validationMessage.isError}>
          {validationMessage.message}
        </ValidationMessage>
      )}
    </div>
  );
};

const EnteryourDelegate = () => {
  const history = useHistory();
  const [validationMessage, setValidationMessage] = useState({
    message: "",
    isError: false,
  });
  let noSwap = useRouteMatch("/manual-delegates-no-swap");
  const {
    data: {
      address,
      delegateSigDetails: _delegateSigDetails,
      delegatedTo: { delegatedTo, loading: delegatedToLoading },
    },
  } = useQuery(gql`
    query customDelegateQuery @client {
      address
      delegateSigDetails
      delegatedTo
    }
  `);
  useGetDelegatedTo(address);
  useGetDelegateBySigStatus(address);

  const delegateSigDetails = _delegateSigDetails.details;

  const [ensNameAddress, setEnsNameAddress] = useState("");
  const [value, setValue] = useState(null);

  useEffect(() => {
    if (address) {
      setValue(getDelegateChoice(address));
    }
  }, [address]);

  useEffect(() => {
    logEvent("enter_delegate_page");
  }, []);

  return (
    <NarrowColumn>
      <ContentBox>
        <Header>
          <Trans>Custom delegate</Trans>
        </Header>
        <Gap height={3} />
        <Content>
          <Trans>
            Delegate your voting power to an ENS name. You can also enter their
            Ethereum address.
          </Trans>
        </Content>
        <Gap height={5} />
        <InputComponent
          placeholder={"delegate.eth"}
          {...{
            validationMessage,
            setValidationMessage,
            ensNameAddress,
            setEnsNameAddress,
            setValue,
            delegatedTo,
            defaultValue: value,
          }}
        />
      </ContentBox>
      <Footer
        disabled={
          validationMessage.isError ||
          !value ||
          delegatedToLoading ||
          _delegateSigDetails.loading
        }
        rightButtonText={
          noSwap ? (
            delegatedToLoading || _delegateSigDetails.loading ? (
              <Trans>Loading...</Trans>
            ) : (
              <Trans>Delegate</Trans>
            )
          ) : (
            <Trans>Next</Trans>
          )
        }
        rightButtonCallback={() => {
          selectedDelegateReactive({
            address: ensNameAddress === "" ? value : ensNameAddress,
            name: null,
          });
          noSwap
            ? history.push("/delegate-tokens-no-swap")
            : history.push("/delegate-tokens");
        }}
        leftButtonText={<Trans>Back</Trans>}
        leftButtonCallback={() => {
          history.push(noSwap ? "/choose-a-delegate" : "/delegates");
        }}
        // TODO: look into gas-free delegation
        // text={
        //   noSwap &&
        //   !_delegateSigDetails.loading &&
        //   (delegateSigDetails?.canSign ? "Gas Free" : "Requires Gas")
        // }
        subText={
          noSwap &&
          delegateSigDetails?.formattedDate && (
            <Trans>
              Next free delegation {delegateSigDetails?.formattedDate}
            </Trans>
          )
        }
      />
    </NarrowColumn>
  );
};

export default EnteryourDelegate;
