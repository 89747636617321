import { ethers } from "ethers";
import { isMobile, verifyInjectedProvider } from "@ensdomains/web3modal";
import metaMask from "./assets/imgs/Metamask";

import {
  addressReactive,
  isConnected,
  addressDetails,
  network,
} from "./apollo";
import { getSwapData } from "./utils/utils";
import { initLocalStorage } from "./pages/ENSConstitution/constitutionHelpers";
import WalletConnectLogo from "./assets/imgs/WalletConnectLogo.svg";

// TODO: inject web3 into UI queries here

const INFURA_ID = "83a701f8f2fa4c54a43c0adc27f2f259";
const WALLETCONNECT_PROJECT_ID = process.env.REACT_APP_WALLETCONNECT_PROJECT_ID;

let provider;
let web3Modal;
let ethersProvider, mainnetEthersProvider;

const hasMetaMask = verifyInjectedProvider("isMetaMask");
const _isMobile = isMobile();

const installMetaMaskOption = {
  "custom-example": {
    display: {
      logo: metaMask,
      name: _isMobile ? "Metamask" : "Install MetaMask",
      description: _isMobile
        ? "Connect to your metamask Wallet"
        : "After installing, refresh your browser.",
    },
    package: {},
    connector: async () => {
      setTimeout(() => {
        if (_isMobile) {
          const metamaskUrl = 'https://metamask.app.link/dapp/swap.hifi.finance';
          
          window.location.href = metamaskUrl;
        } else {
          window.open("https://metamask.io/download");
          localStorage.removeItem("WEB3_CONNECT_CACHED_PROVIDER");
          if (web3Modal) {
            web3Modal.setCachedProvider("");
          }
        }
      });
    },
  },
};

const option = {
  theme: {
    background: "#181818",
    main: "#FFFFFF",
    secondary: "#828282",
    hover: "#626262",
  },
  network: "mainnet", // optional
  cacheProvider: true, // optional
  providerOptions: {
    ...(hasMetaMask && !_isMobile ? {} : installMetaMaskOption),
    "custom-walletconnect": {
      package: () => import("@walletconnect/ethereum-provider"),
      packageFactory: true,
      display: {
        name: "WalletConnect",
        logo: WalletConnectLogo,
        type: "qrcode",
        description: "Scan with WalletConnect to connect",
      },
      options: {
        // Get the project ID from https://cloud.walletconnect.com/
        projecId: WALLETCONNECT_PROJECT_ID,
        infuraId: INFURA_ID,
        chains: [1],
      },
      connector: async (ProviderPackage, options) => {
        const provider = await ProviderPackage.init({
          projectId: options.projecId,
          chains: options.chains,
          showQrModal: true,
        });
        await provider.enable();
        return provider;
      },
    },
    walletlink: {
      package: () => import("walletlink"),
      packageFactory: true,
      options: {
        appName: "HIFI Token Swap",
        jsonRpcUrl: `https://mainnet.infura.io/v3/${INFURA_ID}`,
      },
    },
  },
};

export const connect = async (showPrompt) => {
  try {
    const Web3Modal = (await import("@ensdomains/web3modal")).default;
    web3Modal = new Web3Modal(option);
    const cachedProvider = web3Modal.cachedProvider;
    provider =
      cachedProvider && cachedProvider.length > 0
        ? await web3Modal.connectTo(cachedProvider)
        : showPrompt && (await web3Modal.connect());
    // if (!provider) throw new Error("No provider found");
    return provider;
  } catch (e) {
    if (e && e !== "Modal closed by user") {
      throw e;
    }
  }
};

export const disconnect = async function () {
  if (web3Modal) {
    web3Modal.clearCachedProvider();
  }

  if (provider && provider.disconnect) {
    provider.disconnect();
  }
  // clear connection data and reconnect with infura
  ethersProvider = undefined;
  mainnetEthersProvider = undefined;

  isConnected(false);
  addressReactive(null);
  network(null);
  addressDetails({});
  await initWeb3Read();
};

export const initWeb3Read = async () => {
  // if cache exists, try to connect. else use infura
  if (localStorage.getItem("WEB3_CONNECT_CACHED_PROVIDER")) {
    await initWeb3(false);
  }
  if (!ethersProvider) {
    ethersProvider = new ethers.providers.JsonRpcProvider(
      `https://mainnet.infura.io/v3/${INFURA_ID}`
    );
    try {
      const { chainId } = await ethersProvider.getNetwork();
      if (chainId === 1) {
        mainnetEthersProvider = ethersProvider;
      } else {
        mainnetEthersProvider = new ethers.providers.JsonRpcProvider(
          `https://mainnet.infura.io/v3/${INFURA_ID}`
        );
      }
    } catch (e) {
      console.error(e);
    }
    isConnected(true);
    const net = await ethersProvider.getNetwork();
    network(net.chainId);
  }
};

export const initWeb3 = async (showPrompt = true) => {
  // fix for web3 modal only showing walletconnect prompt if cached
  if (showPrompt) {
    const cached = localStorage.getItem("WEB3_CONNECT_CACHED_PROVIDER");
    const walletconnect = localStorage.getItem("walletconnect");
    if (
      cached &&
      cached === `"walletconnect"` &&
      (!walletconnect || !walletconnect.connected)
    ) {
      localStorage.removeItem("WEB3_CONNECT_CACHED_PROVIDER");
    }
  }
  const web3Provider = await connect(showPrompt);

  web3Provider?.on("chainChanged", async (_chainId) => {
    window.location.reload();
  });

  web3Provider?.on("accountsChanged", async (accounts) => {
    window.location.reload();
  });

  try {
    ethersProvider = new ethers.providers.Web3Provider(web3Provider);
  } catch (e) {
    console.error(e);
  }
  try {
    const { chainId } = await ethersProvider.getNetwork();
    if (chainId === 1) {
      mainnetEthersProvider = ethersProvider;
    } else {
      mainnetEthersProvider = new ethers.providers.JsonRpcProvider(
        `https://mainnet.infura.io/v3/${INFURA_ID}`
      );
    }
  } catch (e) {
    console.error(e);
  }

  const signer = ethersProvider?.getSigner();
  let address;

  if (signer) {
    try {
      address = (await signer.getAddress()).toLowerCase();
    } catch (e) {
      console.error(e);
    }
  }

  if (address) {
    initLocalStorage(address);
    isConnected(true);
    addressReactive(address);
    const net = await ethersProvider.getNetwork();
    network(net.chainId);
    const swapData = await getSwapData(address);
    addressDetails(swapData);

    return;
  }
  isConnected(false);
  addressReactive(null);
};

export const getProvider = () => provider;
export const getEthersProvider = () => ethersProvider;
export const getMainnetEthersProvider = () => mainnetEthersProvider;
