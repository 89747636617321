import { constants, Contract, utils } from "ethers";
import { network } from "../apollo";
import HifiTokenAbi from "../assets/abis/HifiToken.json";
import MftTokenAbi from "../assets/abis/MftToken.json";
import { getEthersProvider } from "../web3modal";
import {
  DELEGATE_GAS_LIMIT,
  GAS_LIMIT,
  getHIFITokenContractAddress,
  getMFTTokenContractAddress,
} from "./consts";
import { sendToDelegateJsonRpc } from "./utils";
import { logEvent } from "./analytics";

// export const hasSwapped = async (address, type = "mainnet") => {
//   try {
//     const response = await fetch(generateMerkleShardUrl(address, type));
//     if (!response.ok) {
//       throw new Error("error getting shard data");
//     }

//     const shardJson = await response.json({ encoding: "utf-8" });
//     let root, shardNybbles, total;

//     if (process.env.REACT_APP_STAGE === "testing" && type === "ep2")
//       ({ root, shardNybbles, total } = testingMerkleRoot);
//     else if (type === "ep2") ({ root, shardNybbles, total } = ep2MerkleRoot);
//     else ({ root, shardNybbles, total } = merkleRoot);

//     const shardedMerkleTree = new ShardedMerkleTree(
//       () => shardJson,
//       shardNybbles,
//       root,
//       BigNumber.from(total)
//     );

//     const provider = getEthersProvider();
//     const signer = provider.getSigner();
//     const airdropContract =
//       type === "mainnet"
//         ? new Contract(getHIFITokenContractAddress(), HifiTokenAbi, signer)
//         : new Contract(
//             getMerkleAirdropContractAddress(),
//             MerkleAirdropAbi.abi,
//             signer
//           );
//     const [entry, proof] = shardedMerkleTree.getProof(address);
//     const index = getIndex(address, entry, proof);
//     const result = await airdropContract.isClaimed(index);
//     return result;
//   } catch (error) {
//     return false;
//   }
// };

export const submitApprove = async (
  setApproveState,
  history,
  type = "mainnet"
) => {
  try {
    const provider = getEthersProvider();
    const signer = provider.getSigner();
    let airdropContractAddress;
    let approveTokensFunc;
    airdropContractAddress = getMFTTokenContractAddress();
    approveTokensFunc = (approveTokens) =>
      approveTokens(getHIFITokenContractAddress(), constants.MaxUint256, {
        gasLimit: GAS_LIMIT,
      });
    const airdropContract = new Contract(
      airdropContractAddress,
      MftTokenAbi,
      signer
    );
    airdropContract.connect(signer);
    const result = await approveTokensFunc(airdropContract.approve);
    await result.wait(1);
    setApproveState({
      state: "SUCCESS",
      message: "",
    });
    return setTimeout(() => {
      logEvent("approve_success");
      history.push({
        pathname: "/summary/swap",
        state: "SWAP",
      });
    }, 2000);
  } catch (error) {
    logEvent("approve_error");
    console.error(error);
    setApproveState({
      state: "ERROR",
      message: error,
    });
  }
};

export const submitSwap = async (
  address,
  balance,
  setSwapState,
  history,
  type = "mainnet"
) => {
  try {
    const provider = getEthersProvider();
    const signer = provider.getSigner();
    let airdropContractAddress;
    let swapTokensFunc;
    airdropContractAddress = getHIFITokenContractAddress();
    swapTokensFunc = async (swapTokens) =>
      swapTokens(balance.mul(await airdropContract.swapRatio()), {
        gasLimit: GAS_LIMIT,
      });
    const airdropContract = new Contract(
      airdropContractAddress,
      HifiTokenAbi,
      signer
    );
    airdropContract.connect(signer);
    const result = await swapTokensFunc(airdropContract.swap);
    setSwapState({
      state: "LOADED",
      message: "",
    });
    await result.wait(1);
    setSwapState({
      state: "SUCCESS",
      message: "",
    });
    return setTimeout(() => {
      logEvent("swap_success");
      history.push("/swap-success");
    }, 2000);
  } catch (error) {
    logEvent("swap_error");
    console.error(error);
    setSwapState({
      state: "ERROR",
      message: error,
    });
  }
};

export async function delegate(address, setDelegateState, noSwap, history) {
  try {
    const provider = getEthersProvider();
    const signer = provider.getSigner();
    const HIFITokenContract = new Contract(
      getHIFITokenContractAddress(),
      HifiTokenAbi,
      signer
    );
    HIFITokenContract.connect(signer);
    const result = await HIFITokenContract.delegate(address, {
      gasLimit: DELEGATE_GAS_LIMIT,
    });
    setDelegateState({
      state: "LOADED",
      message: "",
      tx: result,
    });
    await result.wait(1);
    setDelegateState({
      state: "SUCCESS",
      message: "",
      tx: result,
    });
    return setTimeout(() => {
      logEvent("delegate_success");
      if (noSwap) {
        // history.push("/delegate-success");
      } else {
        history.push("/summary");
      }
    }, 2000);
  } catch (error) {
    logEvent("delegate_error");
    console.error(error);
    setDelegateState({
      state: "ERROR",
      message: error,
    });
  }
}

export async function delegateBySig(address, setSwapState, history, nonce) {
  const expiry = Math.floor(Date.now() / 1000) + 60 * 60 * 24 * 7; // set expiry of signature to 7 days from now
  const message = {
    delegatee: address,
    nonce,
    expiry,
  };
  try {
    const provider = getEthersProvider();
    const signer = provider.getSigner();
    const chainId = network();
    const sig = await signer._signTypedData(
      {
        name: "Ethereum Name Service",
        version: "1",
        chainId: chainId,
        verifyingContract: getHIFITokenContractAddress(chainId),
      },
      {
        Delegation: [
          { name: "delegatee", type: "address" },
          { name: "nonce", type: "uint256" },
          { name: "expiry", type: "uint256" },
        ],
      },
      message
    );
    const { r, s, v } = utils.splitSignature(sig);
    message.r = r;
    message.s = s;
    message.v = v;

    const delegateSigResponseData = await sendToDelegateJsonRpc("delegate", {
      delegatee: address,
      nonce,
      expiry,
      r,
      s,
      v,
    });

    setSwapState({
      state: "LOADED",
      message: delegateSigResponseData,
      tx: result,
    });

    if (!delegateSigResponseData)
      throw new Error("Didn't get response from server");

    setSwapState({
      state: "SUCCESS",
      message: delegateSigResponseData,
      tx: result,
    });
    return delegateSigResponseData;
  } catch (error) {
    console.error(error);
    setSwapState({
      state: "ERROR",
      message: error,
    });
  }
}

export const handleApprove = async (
  setApproveState,
  history,
  type = "mainnet"
) => {
  try {
    setApproveState({
      state: "LOADING",
      message: "",
    });

    return await submitApprove(setApproveState, history, type);
  } catch (error) {
    console.error(error);
    setApproveState({
      state: "ERROR",
      message: error,
    });
  }
};

export const handleSwap = async (
  address,
  balance,
  setSwapState,
  history,
  type = "mainnet"
) => {
  try {
    setSwapState({
      state: "LOADING",
      message: "",
    });

    let outputAddress;
    // let provider = getEthersProvider();
    // let displayName;

    // if (type === "mainnet") {
    //   displayName = getDelegateChoice(address);
    //   if (!displayName) {
    //     throw "No chosen delegate";
    //   }
    // } else {
    //   displayName = address;
    // }

    // if (displayName.includes(".eth")) {
    //   outputAddress = await provider.resolveName(displayName);
    // } else {
    //   outputAddress = displayName;
    // }

    return await submitSwap(
      outputAddress,
      balance,
      setSwapState,
      history,
      type
    );
  } catch (error) {
    console.error(error);
    setSwapState({
      state: "ERROR",
      message: error,
    });
  }
};
