import React, { useEffect } from "react";
import { Trans } from "@lingui/macro";
import { useRouteMatch, Link } from "react-router-dom";
import { selectedDelegateReactive } from "../apollo";
import ReactDOM from "react-dom";
import styled from "styled-components";
import { Hamburger } from "./Hamburger";
import { NavLink } from "./text";
import { LaunchButton } from "./Header";

const Container = styled.div`
  z-index: 200;
  position: fixed;
  top: 0;
  left: 0;
  height: 0;
  width: 100vw;
`;

const Overlay = styled.div`
  position: absolute;
  background-color: black;
  opacity: 0;
  width: 100vw;
  height: 0;
  transition: opacity 0.25s ease-in-out;
  ${({ active }) =>
    active &&
    `
      opacity: .5;
      height: 100vh;
    }
  `}
`;

const InnerContainer = styled.div`
  width: 175px;
  position: absolute;
  right: -175px; /* Start off-screen */
  top: 0;
  height: 100vh;
  background-color: #101010;
  padding-top: 30px;
  transition: right 0.25s ease-in-out;
  ${({ active }) =>
    active &&
    `
      right: 0px;
    }
  `}
`;

const LinksContainer = styled.div`
  padding-top: 30px;
  display: flex;
  flex-direction: column;

  & > * + * {
    margin-top: 40px;
  }
`;

const HamburgerContainer = styled.div`
  padding: 0px 5px;
  width: 30px;
  height: 18px;
`;
const HamburgerContainerContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-right: 10px;
`;

export function MobileNav({
  children,
  hamburgerOn,
  collapseHamburger,
  toggleHamburgerOn,
}) {
  useEffect(() => {
    if (hamburgerOn) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
  }, [hamburgerOn]); // This will fire every time `hamburgerOn` changes.

  let matchDelegate =
    useRouteMatch("/delegate") || useRouteMatch("/choose-a-delegate");

  const portalRoot = document.getElementById("portal-root");
  return ReactDOM.createPortal(
    <Container>
      <Overlay onClick={toggleHamburgerOn} active={hamburgerOn} />
      <InnerContainer active={hamburgerOn}>
        <HamburgerContainerContainer>
          <HamburgerContainer>
            <Hamburger active={hamburgerOn} onClick={toggleHamburgerOn} />
          </HamburgerContainer>
        </HamburgerContainerContainer>
        <LinksContainer>
          <a
            href="https://metrics.hifi.finance"
            tabIndex={hamburgerOn ? 0 : -1}
          >
            <NavLink ismobile>
              <Trans>Metrics</Trans>
            </NavLink>
          </a>
          <Link
            to="/delegate"
            onClick={() => {
              selectedDelegateReactive({ name: null, address: null });
              toggleHamburgerOn();
            }}
            tabIndex={hamburgerOn ? 0 : -1}
          >
            <NavLink ismobile current={matchDelegate}>
              <Trans>Governance</Trans>
            </NavLink>
          </Link>

          <a
            href="https://hifi.finance/roadmap"
            tabIndex={hamburgerOn ? 0 : -1}
          >
            <NavLink ismobile>
              <Trans>Roadmap</Trans>
            </NavLink>
          </a>

          <a href="https://blog.hifi.finance" tabIndex={hamburgerOn ? 0 : -1}>
            <NavLink ismobile>
              <Trans>Blog</Trans>
            </NavLink>
          </a>

          <LaunchButton
            style={{ display: "inline-block", width: "fit-content" }}
            href="https://app.hifi.finance"
            className="link-normal link-launch2"
            target="_blank"
            rel="noreferrer"
          >
            <Trans>Launch App</Trans>
          </LaunchButton>
        </LinksContainer>
      </InnerContainer>
    </Container>,
    portalRoot
  );
}
