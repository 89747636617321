import { normalize } from "@ensdomains/eth-ens-namehash";
import { Contract, ethers } from "ethers";
import { gql } from "graphql-tag";
import { keccak_256 as sha3 } from "js-sha3";
import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  apolloClientInstance,
  currentVotes as currentVotesReactive,
  delegatedTo,
  delegates as delegatesReactive,
  delegateSigDetails as delegateSigDetailsReactive,
  tokensOwned,
} from "../apollo";
import ENSDelegateAbi from "../assets/abis/ENSDelegate.json";
import delegatesList from "../assets/delegates.json";
import HifiTokenAbi from "../assets/abis/HifiToken.json";
// import ReverseRecordsAbi from "../assets/abis/ReverseRecords.json";
import { getDelegateReferral } from "../pages/ENSConstitution/delegateHelpers";
import { getEthersProvider, getMainnetEthersProvider } from "../web3modal";
import {
  ALLOCATION_ENDPOINT,
  getENSDelegateContractAddress,
  // getENSDelegateContractAddress,
  getHIFITokenContractAddress,
  getMFTTokenContractAddress,
  // getReverseRecordsAddress,
} from "./consts";
import { getCanDelegateBySig } from "./utils";

export function useQueryString() {
  return new URLSearchParams(useLocation().search);
}

const DELEGATE_TEXT_QUERY = gql`
  query delegateTextQuery($ensNames: [String]) {
    domains(where: { name_in: $ensNames }, first: 1000) {
      id
      name
      resolvedAddress {
        id
      }
    }
  }
`;

export function namehash(inputName) {
  let node = "";
  for (let i = 0; i < 32; i++) {
    node += "00";
  }

  if (inputName) {
    const labels = inputName.split(".");

    for (let i = labels.length - 1; i >= 0; i--) {
      let labelSha;
      let normalisedLabel = normalize(labels[i]);
      labelSha = sha3(normalisedLabel);
      node = sha3(new Buffer(node + labelSha, "hex"));
    }
  }

  return "0x" + node;
}

const processENSDelegateContractResults = (
  results,
  delegateData,
  delegatedTokens
) =>
  results.map((result) => {
    const data = delegateData.find((data) => {
      return (
        data.resolvedAddress.id.toLowerCase() === result.addr.toLowerCase()
      );
    });
    const delegation = delegatedTokens.find(
      (data) => data.address.toLowerCase() == result.addr.toLowerCase()
    );
    return {
      avatar: result.avatar,
      profile: result.profile,
      address: result.addr,
      votes: delegation.votes,
      name: data?.name,
    };
  });

const bigNumberToDecimal = (bigNumber) =>
  Number(bigNumber.toBigInt() / window.BigInt(Math.pow(10, 18)));

const stringToInt = (numberString) =>
  Number(window.BigInt(numberString) / window.BigInt(Math.pow(10, 18)));

const cleanDelegatesList = (delegatesList) =>
  delegatesList.map((delegateItem) => ({
    avatar: delegateItem.avatar,
    profile: delegateItem.profile,
    address: delegateItem.address?.toLowerCase(),
    name: delegateItem.name,
    votes: bigNumberToDecimal(delegateItem.votes),
    ranking: bigNumberToDecimal(delegateItem.votes),
  }));

const createItemBatches = (items, perBatch = 2) => {
  var result = items.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / perBatch);

    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = []; // start a new chunk
    }

    resultArray[chunkIndex].push(item);

    return resultArray;
  }, []);
  return result;
};

const TARGET_DELEGATE_SIZE = 0.025;
// This function ranks delegates by delegated vote total until they reach the
// target percentage of all delegated votes, at which point their ranking begins to decrease.
const generateRankingScore = (score, total, name) => {
  if (score > total * TARGET_DELEGATE_SIZE) {
    score = Math.max(2 * total * TARGET_DELEGATE_SIZE - score, 0);
  }
  return score;
};

const addBalance = (cleanList, tokensDelegated) => {
  return cleanList.map((item) => {
    return {
      ...item,
      ranking:
        generateRankingScore(item.votes, tokensDelegated, item.name) *
        Math.random(),
    };
  });
};

export const rankDelegates = (
  delegateList,
  tokensDelegated,
  prepopDelegate
) => {
  const cleanList = cleanDelegatesList(delegateList);
  const withTokenBalance = addBalance(cleanList, tokensDelegated);
  const sortedList = withTokenBalance.sort((x, y) => {
    if (x.name == prepopDelegate) return -1;
    if (y.name == prepopDelegate) return 1;
    return y.ranking - x.ranking;
  });
  return sortedList;
};

export const useDelegateVotes = (address) => {
  const [currentVotes, setCurrentVotes] = useState();
  const [loading, setLoading] = useState(true);
  const provider = getEthersProvider();
  const HIFITokenContract = new Contract(
    getHIFITokenContractAddress(),
    HifiTokenAbi,
    provider
  );

  useEffect(() => {
    async function run() {
      const currentVotes = await HIFITokenContract.getCurrentVotes(address);
      setCurrentVotes(
        currentVotes.div(ethers.utils.parseEther("1")).toNumber()
      );
      setLoading(false);
    }
    if (address) {
      run();
    }
  }, [address]);

  return { currentVotes: currentVotes, loading };
};

export const useGetCurrentVotes = (address) => {
  const { currentVotes, loading } = useDelegateVotes(address);
  currentVotesReactive({ currentVotes, loading });
};

export const useGetCurrentVotesHook = (address) => {
  const [currentVotes, setCurrentVotes] = useState();
  const [loading, setLoading] = useState(true);
  const provider = getEthersProvider();
  const HIFITokenContract = new Contract(
    getHIFITokenContractAddress(),
    HifiTokenAbi,
    provider
  );

  useEffect(() => {
    async function run() {
      const currentVotes = await HIFITokenContract.getCurrentVotes(address);
      setCurrentVotes(
        currentVotes.div(ethers.utils.parseEther("1")).toNumber()
      );
      setLoading(false);
    }
    if (address) {
      run();
    }
  }, [address]);

  return currentVotes;
};

export const useGetDelegates = (isConnected) => {
  const delegates = useRef({});
  const loading = useRef(true);
  useEffect(() => {
    const provider = getEthersProvider();
    const mainnetProvider = getMainnetEthersProvider();
    const run = async () => {
      const { data: delegateData } = await apolloClientInstance.query({
        query: DELEGATE_TEXT_QUERY,
        variables: {
          ensNames: delegatesList,
        },
      });
      const filteredDelegateData = delegateData.domains;
      const delegateNamehashes = filteredDelegateData.map(
        (result) => result.id
      );
      const delegateAddresses = filteredDelegateData.map(
        (result) => result.resolvedAddress.id
      );

      const ENSDelegateContract = new Contract(
        getENSDelegateContractAddress(),
        ENSDelegateAbi.abi,
        mainnetProvider
      );

      const HifiTokenContract = new Contract(
        getHIFITokenContractAddress(),
        HifiTokenAbi,
        provider
      );

      // const ReverseRecordsContract = new Contract(
      //   getReverseRecordsAddress(),
      //   ReverseRecordsAbi,
      //   provider
      // );

      const batches = createItemBatches(delegateNamehashes, 50);
      let results = await Promise.all(
        batches.map((batch) => ENSDelegateContract.getDelegates(batch))
      );
      let currentDelegationData = await Promise.all(
        delegateAddresses.map(async (address) => ({
          votes: await HifiTokenContract.getCurrentVotes(address),
          address,
        }))
      );
      const flatResults = results?.flat();
      const processedDelegateData = processENSDelegateContractResults(
        flatResults,
        filteredDelegateData,
        currentDelegationData
      );

      // const addresses = processedDelegateData.map((data) => data.address);
      // const names = await ReverseRecordsContract.getNames(addresses);
      // const processedDelegateDataWithReverse = processedDelegateData.filter(
      //   (d, i) => d.name == names[i]
      // );
      const processedDelegateDataWithReverse = processedDelegateData;

      const tokensDelegated = processedDelegateDataWithReverse
        .map((delegate) => delegate.votes)
        .reduce((a, b) => a.add(b))
        .div(ethers.utils.parseEther("1"))
        .toNumber();
      const rankedDelegates = await rankDelegates(
        processedDelegateDataWithReverse,
        tokensDelegated,
        getDelegateReferral()
      );

      delegates.current = rankedDelegates;
      loading.current = false;
      delegatesReactive({
        delegates: delegates.current,
        loading: loading.current,
      });
    };

    try {
      if (isConnected) {
        run();
      }
    } catch (error) {
      console.error("Error getting delegates: ", error);
    }
  });

  delegatesReactive({ delegates: delegates.current, loading: loading.current });
};

export const useGetTokens = (address) => {
  const [balance, setBalance] = useState();
  const [loading, setLoading] = useState(true);
  const provider = getEthersProvider();
  const HIFITokenContract = new Contract(
    getHIFITokenContractAddress(),
    HifiTokenAbi,
    provider
  );

  useEffect(() => {
    async function run() {
      const balance = await HIFITokenContract.balanceOf(address);
      setBalance(balance);
      setLoading(false);
    }
    if (address) {
      run();
    }
  }, [address]);

  tokensOwned({ balance, loading });
};

export const useGetDelegatedTo = (address) => {
  const [delegatedToAddress, setDelegatedToAddress] = useState();
  const [loading, setLoading] = useState(true);
  const provider = getEthersProvider();
  const HIFITokenContract = new Contract(
    getHIFITokenContractAddress(),
    HifiTokenAbi,
    provider
  );

  useEffect(() => {
    async function run() {
      const delegatedToAddress = await HIFITokenContract.delegates(address);
      setDelegatedToAddress(delegatedToAddress);
      setLoading(false);
    }
    if (address) {
      run();
    }
  }, [address]);

  delegatedTo({ delegatedTo: delegatedToAddress, loading });
};

export const useGetDelegateBySigStatus = (address) => {
  const [delegateSigDetails, setDelegateSigDetails] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function run() {
      // TODO: implement delegation by signature
      // const fetchedDelegateSigDetails = await getCanDelegateBySig(address);
      const fetchedDelegateSigDetails = false;
      setDelegateSigDetails(fetchedDelegateSigDetails);
      setLoading(false);
    }
    if (address) {
      run();
    }
  }, [address]);

  delegateSigDetailsReactive({ details: delegateSigDetails, loading });
};

export const useGetTransactionDone = (txHash) => {
  const [transactionDone, setTransactionDone] = useState(true);
  const [loading, setLoading] = useState(true);
  const provider = getEthersProvider();

  useEffect(() => {
    async function run() {
      if (!provider) return;
      const tx = await provider.getTransaction(txHash);
      setTransactionDone(tx.blockNumber !== null);
      setLoading(false);
    }
    if (txHash) {
      run();
    }
  }, [txHash, provider]);

  return transactionDone;
};
