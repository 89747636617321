import {
  getDelegateRpcURL,
  getHIFITokenContractAddress,
  getMFTTokenContractAddress,
  networkIdToName,
  supportedAvatarProtocols,
} from "./consts";
import HifiTokenAbi from "../assets/abis/HifiToken.json";
import MftTokenAbi from "../assets/abis/MftToken.json";
import { constants, Contract } from "ethers";
import { getEthersProvider } from "../web3modal";

export const formatTokenAmount = (tokenAmount, length = 6) =>
  new Intl.NumberFormat("en-US", {
    minimumFractionDigits: length,
    maximumFractionDigits: length,
  }).format(Number(tokenAmount) / Math.pow(10, 18));

export function imageUrl(url, name, networkId) {
  const _network = networkIdToName(networkId);
  const _protocol = supportedAvatarProtocols.find((proto) =>
    url.startsWith(proto)
  );
  // check if given uri is supported
  // provided network name is valid,
  // domain name is available
  if (_protocol && _network && name) {
    return `https://metadata.ens.domains/${_network}/avatar/${name}`;
  }
  console.warn("Unsupported avatar", _network, name, url);
}

export const shortenAddress = (
  address = "",
  maxLength = 10,
  leftSlice = 5,
  rightSlice = 5
) => {
  if (address.length < maxLength) {
    return address;
  }

  return `${address.slice(0, leftSlice)}...${address.slice(-rightSlice)}`;
};

export const getSwapData = async (address, type = "mainnet") => {
  const provider = getEthersProvider();

  const HIFITokenContract = new Contract(
    getHIFITokenContractAddress(),
    HifiTokenAbi,
    provider
  );

  const MFTTokenContract = new Contract(
    getMFTTokenContractAddress(),
    MftTokenAbi,
    provider
  );

  const swapRatio = await HIFITokenContract.swapRatio();
  const hifiBal = await HIFITokenContract.balanceOf(address);
  const mftBal = await MFTTokenContract.balanceOf(address);
  const hifiSwapBal = mftBal.div(swapRatio);

  const hifiBalance = formatTokenAmount(hifiBal);
  const shortHifiBalance = formatTokenAmount(hifiBal, 2);
  const hifiSwapBalance = formatTokenAmount(hifiSwapBal);
  const shortHifiSwapBalance = formatTokenAmount(hifiSwapBal, 2);
  const mftBalance = formatTokenAmount(mftBal, 4);
  const shortMftBalance = formatTokenAmount(mftBal, 2);

  const hasApproval = (
    await MFTTokenContract.allowance(address, getHIFITokenContractAddress())
  ).gte(mftBal);

  const hasDelegatee =
    (await HIFITokenContract.delegates(address)).toLowerCase() !==
    constants.AddressZero;

  return {
    hifiBalance,
    hifiSwapBalance,
    mftBalance,
    shortHifiBalance,
    shortHifiSwapBalance,
    shortMftBalance,
    rawHifiBalance: hifiBal,
    rawHifiSwapBalance: hifiSwapBal,
    rawMftBalance: mftBal,
    hasApproval,
    hasDelegatee,
    eligible: true,
  };
};

export const sendToDelegateJsonRpc = async (method, params) => {
  return fetch(getDelegateRpcURL(), {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      jsonrpc: "2.0",
      method,
      params,
      id: 1,
    }),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.error) {
        throw new Error(res.error.message);
      }
      return res.result;
    });
};

export const getCanDelegateBySig = async (address) => {
  const delegateSigData = await sendToDelegateJsonRpc("query", {
    address,
  });
  const currentDate = Date.now();

  if (delegateSigData && delegateSigData.next !== undefined) {
    if (delegateSigData.next * 1000 < currentDate) {
      delegateSigData.canSign = true;
    } else {
      delegateSigData.canSign = false;
      const dateFromNext = new Date(delegateSigData.next * 1000);
      const distance = dateFromNext - currentDate;
      if (distance < 7200000) {
        delegateSigData.formattedDate =
          "in " + (distance / 1000 / 60).toFixed() + " minutes";
      } else if (distance < 259200000) {
        delegateSigData.formattedDate =
          "in " + (distance / 1000 / 3600).toFixed() + " hours";
      } else {
        delegateSigData.formattedDate =
          "on " + dateFromNext.toLocaleDateString();
      }
    }
  }

  return delegateSigData;
};

export const numberWithCommas = (num) => {
  const numStr = num.toString();
  let decimalPart = "";
  const wholeNum = Math.floor(parseFloat(numStr));
  if (numStr.indexOf(".") != -1) {
    decimalPart = "." + numStr.split(".")[1];
  }
  const array = wholeNum.toString().split("");
  let index = -3;
  while (array.length + index > 0) {
    array.splice(index, 0, ",");
    // Decrement by 4 since we just added another unit to the array.
    index -= 4;
  }
  return array.join("") + decimalPart;
};
