export const SCREEN_SIZES = {
  mobileS: 320,
  mobileM: 375,
  mobileL: 600,
  tablet: 768,
  desktopS: 900,
  desktopL: 1230,
  desktopML: 1330,
  desktopXL: 1565,
}

export const footerHeight = '30.375rem'
export const sideSpacing = {
  max: '16.25rem',
  xxxlarge: '12rem',
  xxlarge: '9rem',
  xlarge: '7rem',
  large: '2.5rem',
  medium: '2.35rem',
}
export const contentWidthMin = '12.5rem'
export const maxWidthMax = '120rem'
export const hamburgerBarHeight = 2
export const hamburgerBarSpacing = 4

export const MEDIA_SIZES = {
  min: {
    phoneS: `min-width: ${SCREEN_SIZES.mobileS}px`,
    phoneM: `min-width: ${SCREEN_SIZES.mobileM}px`,
    phoneL: `min-width: ${SCREEN_SIZES.mobileL}px`,
    tablet: `min-width: ${SCREEN_SIZES.tablet}px`,
    desktopS: `min-width: ${SCREEN_SIZES.desktopS}px`,
    desktopL: `min-width: ${SCREEN_SIZES.desktopL}px`,
    desktopXL: `min-width: ${SCREEN_SIZES.desktopXL}px`,
  },
  max: {
    phoneS: `max-width: ${SCREEN_SIZES.mobileS}px`,
    phoneM: `max-width: ${SCREEN_SIZES.mobileM}px`,
    phoneL: `max-width: ${SCREEN_SIZES.mobileL}px`,
    tablet: `max-width: ${SCREEN_SIZES.tablet}px`,
    desktopS: `max-width: ${SCREEN_SIZES.desktopS}px`,
    desktopL: `max-width: ${SCREEN_SIZES.desktopL}px`,
    desktopML: `max-width: ${SCREEN_SIZES.desktopML}px`,
    desktopXL: `max-width: ${SCREEN_SIZES.desktopXL}px`,
  },
}

export const COLORS = {
  BLACK: '#101010',
  DARK_GREY: '#181818',
  LIGHT_GREY: '#363636',
  GREY: '#202020',
  WHITE: '#FFFFFF',
  PURPLE: '#6E2EF4',
  BLUE: '#2C6FF6',
  PURPLE_DARK: '#5B28C6',
  ORANGE: '#F48D2E',
}
