import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { gql } from "graphql-tag";
import styled from "styled-components";

import {
  ContentBox,
  ContentBoxWithColumns,
  InnerContentBox,
  InnerContentBoxColumn,
  InnerContentBoxRow,
  NarrowColumn,
} from "../components/layout";
import {
  Content,
  DecimalBalance,
  Header,
  IntegerBalance,
  Link,
  Statistic,
  SubsubTitle,
  SubTitle,
} from "../components/text";
import Gap from "../components/Gap";
import { useHistory } from "react-router-dom";
import { largerThan } from "../utils/styledComponents";

import { ReactComponent as SplashHifiLogo } from "../assets/imgs/SplashHifiLogo.svg";
import { ReactComponent as SplashMainframeLogo } from "../assets/imgs/SplashMainframeLogo.svg";
import Divider from "../components/Divider";
import Pill from "../components/Pill";
import { CTAButton } from "../components/buttons";
import Profile from "../components/Profile";
import { Trans } from "@lingui/macro";
import { logEvent } from "../utils/analytics";

const SwapToHifiTokenContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: center;

  ${largerThan.tablet`
        flex-direction: row;
    `}
`;

const HifiLogo = styled(SplashHifiLogo)`
  width: 40px;
  height: 40px;
  margin-left: 5px;
  marign-top: 0px;
`;

const MainframeLogo = styled(SplashMainframeLogo)`
  width: 40px;
  height: 40px;
  margin-left: 5px;
  marign-top: 0px;
`;

const SmallHifiLogo = styled(HifiLogo)`
  width: 25px;
  height: 25px;
  margin-top: 1px;
  margin-left: 5px;
  margin-right: -4px;
`;

const StatsSubtitle = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.01em;
  color: #717171;
  opacity: 0.6;
`;

const RowLabel = styled.div`
  letter-spacing: -0.01em;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #717171;
  display: flex;
  align-items: center;
`;

const StatsRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px;
  box-sizing: border-box;
`;

const StatsSection = styled.div`
  margin-bottom: 35px;
`;

const StatsNumber = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  text-align: right;
  color: #000000;
`;

const NumberWithLogoContainer = styled.div`
  display: flex;
  align-items: center;
`;

const WrappedContent = styled(Content)`
  color: #ffffff;
`;

const AdditionalSubtitle = styled(SubsubTitle)`
  margin-left: 10px;
`;

const AdditionalHeader = styled(Header)`
  font-size: 20px;
`;

const AdditionalIntegerBalance = styled(IntegerBalance)`
  font-size: 20px;
  line-height: 24px;
`;

const AdditionalDecimalBalance = styled(DecimalBalance)`
  font-size: 20px;
  line-height: 24px;
`;

const AdditionalLink = styled(Link)`
  font-size: 20px;
`;

const AdditionalContent = styled(Content)`
  font-size: 14px;
`;

const EndAlignedCTAButton = styled(CTAButton)`
  align-self: flex-end;
`;

const Dashboard = () => {
  const {
    data: { address, addressDetails, network },
  } = useQuery(gql`
    query getHeaderData @client {
      address
      addressDetails
      network
    }
  `);

  const {
    // lastExpiringName,
    // longestOwnedName,
    // pastTokens,
    // futureTokens,
    hifiSwapBalance,
    mftBalance,
    rawHifiSwapBalance,
    rawMftBalance,
    hasDelegatee,
    // hasReverseRecord,
    // eligible,
  } = addressDetails;

  const history = useHistory();

  // const [isClaimed, setIsClaimed] = useState(false);
  // const [isClaimedLoading, setIsClaimedLoading] = useState(true);

  // const [isClaimedEp2, setIsClaimedEp2] = useState(false);
  // const [isClaimedEp2Loading, setIsClaimedEp2Loading] = useState(true);

  // useEffect(() => {
  //   hasSwapped(address)
  //     .then((result) => {
  //       setIsClaimed(result);
  //       setIsClaimedLoading(false);
  //     })
  //     .then(() => hasSwapped(address, "ep2"))
  //     .then((result) => {
  //       console.log(result);
  //       // setIsClaimedEp2(result);
  //       // setIsClaimedEp2Loading(false);
  //     })
  //     .catch((error) => {
  //       console.error("error checking hasSwapped: ", error);
  //     });
  // }, [address]);

  return (
    <SwapToHifiTokenContainer>
      <NarrowColumn>
        {/* {isClaimedLoading === false ? (
            true ? (
              <Pill
                text={
                  isClaimed
                    ? "You were eligible for the airdrop!"
                    : "You are eligible for the airdrop!"
                }
              />
            ) : (
              <Pill error text={"You are not eligible for the airdrop"} />
            )
          ) : null} */}

        <ContentBox>
          <Header>
            {rawHifiSwapBalance?.gt(0) ? (
              <Trans>Swap your tokens</Trans>
            ) : (
              <Trans>You don't have any MFT to swap!</Trans>
            )}
          </Header>
          <Gap height={3} />
          <WrappedContent>
            {rawHifiSwapBalance?.gt(0) ? (
              <Trans>View your tokens below and start the swap process.</Trans>
            ) : (
              <Trans>
                This Ethereum account does not have any MFT to swap. Please make
                sure you are connected with the right account.
              </Trans>
            )}
          </WrappedContent>
          <Gap height={5} />
          {rawHifiSwapBalance?.gt(0) && (
            <InnerContentBox>
              <SubsubTitle>
                <Trans>You currently have...</Trans>
              </SubsubTitle>
              <Gap height={2} />
              <Statistic>
                <IntegerBalance>{mftBalance?.split(".")[0]}</IntegerBalance>
                <DecimalBalance>.{mftBalance?.split(".")[1]}</DecimalBalance>
                <MainframeLogo />
              </Statistic>
            </InnerContentBox>
          )}
          {rawHifiSwapBalance?.gt(0) && <Gap height={2} />}
          {rawHifiSwapBalance?.gt(0) && (
            <InnerContentBox>
              <SubsubTitle>
                <Trans>You will receive...</Trans>
              </SubsubTitle>
              <Gap height={2} />
              <Statistic>
                <IntegerBalance>
                  {hifiSwapBalance?.split(".")[0]}
                </IntegerBalance>
                <DecimalBalance>
                  .{hifiSwapBalance?.split(".")[1]}
                </DecimalBalance>
                <HifiLogo />
              </Statistic>
            </InnerContentBox>
          )}
          <Gap height={4} />
          {true && (
            <>
              <Gap height={5} />
              <CTAButton
                text={
                  rawHifiSwapBalance?.gt(0) ? (
                    <Trans>Confirm Swap</Trans>
                  ) : (
                    <Trans>Get MFT to swap</Trans>
                  )
                }
                // TODO: delete dead "/why" route
                onClick={() =>
                  rawHifiSwapBalance?.gt(0)
                    ? history.push("/summary")
                    : (function () {
                        logEvent("buy_mft");
                        let a = document.createElement("a");
                        a.target = "_blank";
                        a.href =
                          "https://coinmarketcap.com/currencies/mainframe/markets";
                        a.click();
                      })()
                }
                // type={isClaimed || isClaimedLoading ? "disabled" : ""}
              />
            </>
          )}
        </ContentBox>
        <Gap height={6} />
      </NarrowColumn>
    </SwapToHifiTokenContainer>
  );
};

export default Dashboard;
