import styled from "styled-components";

import theme from "./theme";

export const Link = styled.a`
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.01em;
  color: #828282;
  text-decoration: none;

  &:hover {
    color: #6e2ef4;
  }

  &:active {
    color: #6e2ef4;
  }
`;

export const Title = styled.div`
  font-size: 72px;
  color: #fff;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
  font-size: 50px;
  line-height: 60px;
  text-align: center;
  letter-spacing: -0.01em;
`;

export const SubTitle = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 150%;
  text-align: center;
  letter-spacing: -0.01em;
  color: #ffffff;
`;

export const SubsubTitle = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.01em;
  color: #828282;
`;

export const Header = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 31px;
  display: flex;
  align-items: center;
  letter-spacing: -0.01em;
  color: #ffffff;
`;

export const Content = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 141%;
  color: #ffffff;
`;

export const Statistic = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  line-height: 141%;
  letter-spacing: -0.01em;
  color: #000000;
  display: flex;
`;

export const TokenText = styled.span`
  color: #6e2ef4;
  font-weight: bold;
`;

export const IntegerBalance = styled.span`
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 141%;
  letter-spacing: -0.01em;
  color: #ffffff;
`;

export const DecimalBalance = styled.span`
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 141%;
  letter-spacing: -0.01em;
  color: #bfbfbf;
`;

export const NavLink = styled.span`
  /* About */

  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  margin-right: 60px;
  color: #ffffff;

  &:hover {
    color: #6e2ef4;
  }

  ${({ ismobile }) =>
    ismobile &&
    `
    font-weight: 600;
    font-size: 16px;
    margin-left: 24px;
    margin-right: 0px;
  `}

  ${(p) =>
    p.current &&
    `
    color: #6E2EF4;
  `}
`;
