import React, { useEffect } from "react";
import styled from "styled-components";
import useWindowSize from "react-use/lib/useWindowSize";
import Confetti from "react-confetti";

import { Content, Header } from "../components/text";
import { ContentBox, NarrowColumn } from "../components/layout";
import Gap from "../components/Gap";
import { useHistory } from "react-router-dom";
import { CTAButton } from "../components/buttons";
import TwitterLogo from "../assets/imgs/Twitter.svg";
import DiscordInnerLogo from "../assets/imgs/DiscordInner.svg";
import TwitterInnerLogo from "../assets/imgs/TwitterInner.svg";
import DiscordLogo from "../assets/imgs/Discord.svg";
import { Trans } from "@lingui/macro";
import { logEvent } from "../utils/analytics";

const Confet = () => {
  const { width, height } = useWindowSize();
  return <Confetti width={width} height={height} style={{ zIndex: "-2" }} />;
};

const SocialButtonContainer = styled.div`
  background: ${(p) => (p.type === "Twitter" ? "#EBF3FF" : "#F3EFFF")};
  border-radius: 14px;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 25px;
  letter-spacing: -0.01em;
  color: ${(p) => (p.type === "Twitter" ? "#4D90F1" : "#854BFF")};
  cursor: pointer;
`;

const TWITTER_INTENT =
  "Hifi%20Finance%20is%20decentralizing%20governance!%20%40HifiFinance%20is%20an%20open%20fixed-rate%20lending%20protocol%20owned%20by%20the%20community.%0A%0AI%20just%20swapped%20my%20%24MFT%20for%20%24HIFI%20governance%20tokens.%20Swap%20yours%20%F0%9F%91%87%0A%0Aswap.hifi.finance";

const SocialButton = ({ type, text }) => {
  return (
    <a
      href={
        type === "Twitter"
          ? `https://twitter.com/intent/tweet?text=${TWITTER_INTENT}`
          : "https://discord.com/invite/uGxaCppKSH"
      }
      target={"_blank"}
    >
      <SocialButtonContainer type={type}>
        <div>{text}</div>
        <img src={type === "Twitter" ? TwitterLogo : DiscordLogo} />
      </SocialButtonContainer>
    </a>
  );
};

const HifiSwapSuccess = () => {
  const history = useHistory();

  useEffect(() => {
    logEvent("swap_success_page");
  }, []);

  return (
    <>
      <Confet />
      <NarrowColumn>
        <ContentBox>
          <Header>
            <Trans>Swap Successful!</Trans>
          </Header>
          <Gap height={3} />
          <Content>
            <Trans>
              Congratulations! We encourage you to delegate your tokens to a
              trusted community member to represent you in voting on future Hifi
              DAO proposals.
            </Trans>
          </Content>
          <Gap height={10} />
          <CTAButton
            text={<Trans>Delegate Tokens</Trans>}
            style={{ width: "312px", height: "50px", margin: "auto" }}
            onClick={() => {
              logEvent("delegate_after_swap");
              history.push("/delegate");
            }}
          />
          <Gap height={7} />
          <CTAButton
            text={<Trans>Follow on X</Trans>}
            style={{
              width: "312px",
              height: "50px",
              margin: "auto",
              border: "1px solid #FFF",

              backgroundColor: "transparent",
              backgroundImage: `url(${TwitterInnerLogo})`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "bottom 12px left 78px",
            }}
            onClick={() =>
              (function () {
                logEvent("follow_on_x");
                let a = document.createElement("a");
                a.target = "_blank";
                a.href = "https://twitter.com/HifiFinance";
                a.click();
              })()
            }
            type={"deny"}
          />
          <Gap height={7} />
          <CTAButton
            text={<Trans>Join the Discord</Trans>}
            style={{
              width: "312px",
              height: "50px",
              margin: "auto",
              border: "1px solid #FFF",
              backgroundColor: "transparent",
              backgroundImage: `url(${DiscordInnerLogo})`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "bottom 10px left 58px",
            }}
            onClick={() =>
              (function () {
                logEvent("join_discord");
                let a = document.createElement("a");
                a.target = "_blank";
                a.href = "https://discord.com/invite/uGxaCppKSH";
                a.click();
              })()
            }
            type={"deny"}
          />
        </ContentBox>
      </NarrowColumn>
    </>
  );
};

export default HifiSwapSuccess;
