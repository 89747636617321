import React, { useEffect } from "react";
import styled from "styled-components/macro";

import { Link } from "../components/text";
import Gap from "../components/Gap";

import Governance from "../assets/imgs/Governance.png";
import Delegate from "../assets/imgs/Delegate.png";
import Vote from "../assets/imgs/Vote.png";
import Forum from "../assets/imgs/Forum.png";
import { useHistory } from "react-router-dom";
import { gql } from "graphql-tag";
import { useQuery } from "@apollo/client";
import { initWeb3 } from "../web3modal";
import { CTAButton } from "../components/buttons";
import { Trans } from "@lingui/macro";
import { logEvent } from "../utils/analytics";

const HomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 24px;
  width: 100wv;

  @media only screen and (max-width: 400px) {
    padding: 40px 16px 0;
  }
`;

const WrappedTitle = styled.div`
  font-weight: 500;
  font-size: 70px;
  line-height: 118%;
  font-family: "BananaGrotesk";
  text-align: center;
  letter-spacing: -0.01em;
  background: #ffffff;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 30px;

  @media only screen and (max-width: 400px) {
    font-size: 40px;
    margin-bottom: 10px;
  }
`;

const WrappedSubTitleBlock = styled.div`
  max-width: 925px;
  margin-bottom: 40px;

  @media only screen and (max-width: 400px) {
    margin-bottom: 0px;
  }
`;

const WrappedSubTitle = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 150%;
  letter-spacing: -0.01em;
  color: #ffffff;

  @media only screen and (max-width: 400px) {
    font-size: 16px;
  }
`;

const HOME_QUERY = gql`
  query privateRouteQuery @client {
    addressDetails
    address
  }
`;

const GovernanceImage = styled.img`
  margin-bottom: 60px;
  height: auto;
  width: 100%;
  max-width: 550px;

  @media only screen and (max-width: 400px) {
    margin-bottom: 30px;
  }
`;

const BlockImage = styled.img`
  width: 100%;
  height: auto;
  max-width: 520px;
  margin: 30px;
`;
const BlockText = styled.div`
  max-width: 500px;
  margin: 30px;
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 400px) {
    margin: 10px 0 0;
    align-items: center;
    text-align: center;
  }
`;
const BlockTitle = styled.div`
  font-size: 48px;
  font-weight: 500;
  font-family: "BananaGrotesk";
  color: white;
  line-height: 65px;
  margin-bottom: 30px;

  @media only screen and (max-width: 400px) {
    font-size: 30px;
    line-height: 35px;
    margin-bottom: 10px;
  }
`;

const Block = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 100px;

  @media only screen and (max-width: 400px) {
    margin-top: 80px;
  }
`;

const BlockItem = styled.div`
  max-width: 480px;
`;

const HomeDelegate = () => {
  const {
    data: { address },
  } = useQuery(HOME_QUERY);
  const history = useHistory();

  const handleClick = () => {
    logEvent("get_started");
    history.push("/choose-a-delegate");
  };

  useEffect(() => {
    logEvent("home_page");
  }, []);

  return (
    <HomeContainer>
      <GovernanceImage src={Governance} />
      <WrappedTitle>
        <Trans>Hifi Governance</Trans>
      </WrappedTitle>
      <WrappedSubTitleBlock>
        <WrappedSubTitle style={{ textAlign: "center" }}>
          <Trans>
            Hifi Governance is a community-driven process that manages the
            Lending Protocol, allowing participants to propose, vote on, and
            enact changes. These proposals can adjust system settings, introduce
            new markets, add collateral types, or allocate resources for
            particular initiatives.
          </Trans>
        </WrappedSubTitle>
      </WrappedSubTitleBlock>
      <Block>
        <BlockImage src={Delegate} />
        <BlockText>
          <BlockTitle>
            <Trans>Delegate $HIFI</Trans>
          </BlockTitle>
          <WrappedSubTitle>
            <Trans>
              HIFI is an ERC-20 token that allows its owners to delegate voting
              rights to any address, including their own. The delegate's voting
              rights are automatically adjusted when the owner's token balance
              changes. 
            </Trans>
          </WrappedSubTitle>
          <Gap height={8} />
          <CTAButton
            style={{ width: "fit-content" }}
            text={<Trans>Delegate HIFI</Trans>}
            onClick={handleClick}
          />
        </BlockText>
      </Block>
      <Block style={{ flexWrap: "wrap-reverse" }}>
        <BlockText>
          <BlockTitle>
            <Trans>Governance Forum</Trans>
          </BlockTitle>
          <WrappedSubTitle>
            <Trans>
              The Hifi Governance Forum is where all official discussion
              regarding Hifi Improvement Proposals (HIPs) happens. Read, create,
              and comment on proposals to improve the protocol.
            </Trans>
          </WrappedSubTitle>
          <Gap height={8} />
          <CTAButton
            style={{ width: "fit-content" }}
            text={
              <a
                href="https://forum.hifi.finance/"
                style={{
                  color: "inherit",
                  padding: "16px 14px",
                  margin: "-16px -14px",
                }}
                target="_blank"
              >
                <Trans>Governance Forum</Trans>
              </a>
            }
          />
        </BlockText>
        <BlockImage src={Forum} />
      </Block>
      <Block style={{ marginBottom: 50 }}>
        <BlockImage src={Vote} />
        <BlockText>
          <BlockTitle>
            <Trans>Vote on Proposals</Trans>
          </BlockTitle>
          <WrappedSubTitle>
            <Trans>
              All Hifi Improvement Proposals are voted on using Tally. Tally is
              one of the most trusted names in DAO management and allows for
              easy on-chain voting. All proposals must meet quorum and have
              majority support in order to pass.
            </Trans>
          </WrappedSubTitle>
          <Gap height={8} />
          <CTAButton
            style={{ width: "fit-content" }}
            text={
              <a
                href="https://www.tally.xyz/gov/hifi-dao"
                style={{
                  color: "inherit",
                  padding: "16px 14px",
                  margin: "-16px -14px",
                }}
                target="_blank"
              >
                <Trans>Vote on Tally</Trans>
              </a>
            }
          />
        </BlockText>
      </Block>
    </HomeContainer>
  );
};

export default HomeDelegate;
