import { useQuery } from "@apollo/client";
import TokenDelegation from "../components/TokenDelgation";
import styled from "styled-components/macro";
import { gql } from "graphql-tag";
import React, { useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { selectedDelegateReactive } from "../apollo";
import EtherscanBox from "../components/EtherscanBox";
import Footer from "../components/Footer";
import Gap from "../components/Gap";
import { ContentBox, NarrowColumn } from "../components/layout";
import Pill from "../components/Pill";
import { Content, Header } from "../components/text";
import TransactionState from "../components/TransactionState";
import { delegate, delegateBySig } from "../utils/token";
import { getEthersProvider } from "../web3modal";
import { Trans } from "@lingui/macro";
import { logEvent } from "../utils/analytics";
import { ReactComponent as XIcon } from "../assets/imgs/X.svg";

const Overlay = styled.div`
  z-index: 100000000;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 9999;
`;

const delegateToAddress = async (
  setDelegateState,
  noSwap,
  history,
  delegateAddress,
  sigDetails
) => {
  try {
    setDelegateState({
      state: "LOADING",
      message: "",
    });

    let provider = getEthersProvider();

    if (!delegateAddress || delegateAddress === "") {
      throw "No chosen delegate";
    }

    const tx =
      sigDetails && sigDetails.canSign
        ? await delegateBySig(
            delegateAddress,
            setDelegateState,
            history,
            sigDetails.nonce
          )
        : await delegate(delegateAddress, setDelegateState, noSwap, history);
    // selectedDelegateReactive("");
    return tx;
  } catch (error) {
    console.error(error);
    setDelegateState({
      state: "ERROR",
      message: error,
    });
  }
};

const DelegateTokens = ({ location }) => {
  let noSwap = useRouteMatch("/delegate-tokens-no-swap");

  const {
    data: {
      isConnected,
      address,
      selectedDelegate,
      delegateSigDetails: _delegateSigDetails,
    },
  } = useQuery(gql`
    query privateRouteQuery @client {
      isConnected
      address
      selectedDelegate
      delegateSigDetails
    }
  `);
  const delegateSigDetails = _delegateSigDetails && _delegateSigDetails.details;
  const history = useHistory();
  if (history.action === "POP") {
    history.push("/");
  }
  const [swapState, setSwapState] = useState({
    state: "LOADING",
    message: "",
    tx: null,
  });

  useEffect(() => {
    let timeout;
    const run = async () => {
      if (address) {
        timeout = await delegateToAddress(
          setSwapState,
          noSwap,
          history,
          selectedDelegate.address,
          delegateSigDetails
        );
      }
    };

    run();
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [address]);

  useEffect(() => {
    logEvent("delegate_tokens_page");
  }, []);

  return (
    <>
      <TokenDelegation />
      <Overlay />
      <NarrowColumn style={{ zIndex: 10000, position: "absolute" }}>
        {/*swapState.state === "ERROR" && (
          <Pill error text={swapState.message.message} />
        )*/}
        <ContentBox
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            position: "relative",
          }}
        >
          <XIcon
            style={{
              right: 20,
              top: 20,
              cursor: "pointer",
              width: "24px",
              height: "24px",
              position: "absolute",
            }}
            onClick={() => history.replace("/choose-a-delegate")}
          />

          <TransactionState transactionState={swapState.state} />
          <Header style={{ marginTop: 25 }}>
            {swapState.state === "LOADING" && <Trans>Confirm Delegation</Trans>}
            {swapState.state === "LOADED" && <Trans>Delegation Submitted</Trans>}
            {swapState.state === "ERROR" && <Trans>Delegation Failed</Trans>}
            {swapState.state === "SUCCESS" && (
              <Trans>Delegation Successful</Trans>
            )}
          </Header>
          <Gap height={3} />
          <Content>
            {swapState.state === "LOADING" && (
              <Trans>Proceed in your wallet</Trans>
            )}
            {(swapState.state === "LOADED" ||
              swapState.state === "SUCCESS") && (
              <a
                style={{ color: "#9F71FF" }}
                href={`https://etherscan.io/tx/${swapState.tx.hash}`}
                target="_blank"
              >
                <Trans>View Transaction</Trans>
              </a>
            )}
          </Content>
        </ContentBox>
        {/*swapState.state === "LOADED" && (
          <>
            <Gap height={3} />
            <EtherscanBox
              message={<Trans>Your transaction is queued</Trans>}
              transactionHash={swapState.message}
            />
          </>
        )*/}
      </NarrowColumn>
    </>
  );
};

export default DelegateTokens;
