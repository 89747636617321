import React, { useEffect, useState } from "react";
import { gql } from "graphql-tag";
import { useQuery } from "@apollo/client";

import Footer from "../components/Footer";
import { Content, Header } from "../components/text";
import { ContentBox, NarrowColumn } from "../components/layout";
import Gap from "../components/Gap";
import { useHistory } from "react-router-dom";
import TransactionState from "../components/TransactionState";
import Pill from "../components/Pill";
import { handleSwap } from "../utils/token";
import { Trans } from "@lingui/macro";
import { logEvent } from "../utils/analytics";

const getRightButtonText = (state) => {
  switch (state) {
    case "LOADING":
      return <Trans>Swapping...</Trans>;
    case "SUCCESS":
      return <Trans>Continuing...</Trans>;
    case "ERROR":
      return <Trans>Try again</Trans>;
  }
};

const HifiTokenSwap = ({ location }) => {
  const {
    data: { isConnected, address, addressDetails },
  } = useQuery(gql`
    query privateRouteQuery @client {
      isConnected
      address
      addressDetails
    }
  `);
  const history = useHistory();
  if (history.action === "POP") {
    history.push("/");
  }
  const [swapState, setSwapState] = useState({
    state: "LOADING",
    message: "",
  });

  useEffect(() => {
    let timeout;
    const run = async () => {
      if (
        location.state === "SWAP" &&
        isConnected &&
        addressDetails.rawHifiSwapBalance
      ) {
        timeout = await handleSwap(
          address,
          addressDetails.rawHifiSwapBalance,
          setSwapState,
          history
        );
      }
    };

    run();
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [isConnected]);

  useEffect(() => {
    logEvent("token_swap_page");
  }, []);

  return (
    <NarrowColumn>
      {swapState.state === "ERROR" && (
        <Pill error text={swapState.message.message} />
      )}
      <ContentBox>
        <Header>
          <Trans>Confirm with wallet</Trans>
        </Header>
        <Gap height={3} />
        <Content>
          <Trans>Please submit the transaction to swap your tokens.</Trans>
        </Content>
        <Gap height={6} />
        <TransactionState
          transactionState={swapState.state}
          title={<Trans>Swap</Trans>}
          content={
            <Trans>
              This transaction happens on-chain, and will require paying gas
            </Trans>
          }
        />
      </ContentBox>
      <Footer
        leftButtonText={<Trans>Back</Trans>}
        leftButtonCallback={() => {
          history.push("/summary");
        }}
        rightButtonText={getRightButtonText(swapState.state)}
        rightButtonCallback={() => {
          if (swapState.state === "SUCCESS") {
            history.push("/swap-success");
            return;
          }
          handleSwap(
            address,
            addressDetails.rawHifiSwapBalance,
            setSwapState,
            history
          );
        }}
        disabled={swapState.state === "LOADING" ? "disabled" : ""}
      />
    </NarrowColumn>
  );
};

export default HifiTokenSwap;
