import { useQuery } from "@apollo/client";
import { gql } from "graphql-tag";
import React, { useEffect, useState } from "react";
import {
  HashRouter as Router,
  Route,
  Redirect,
  Switch,
  useHistory,
  useRouteMatch,
} from "react-router-dom";
import styled from "styled-components/macro";
import gradientHalo from "./assets/imgs/gradient-halo.svg";
import Header from "./components/Header";
import SharedFooter from "./components/SharedFooter";
import ChooseYourDelegate from "./pages/ChooseYourDelegate";
import Dashboard from "./pages/Dashboard";
import DelegateRanking from "./pages/DelegateRanking";
import DelegateTokens from "./pages/DelegateTokens";
import Delegation from "./pages/Delegation";
import HifiSwapSuccess from "./pages/HifiSwapSuccess";
import {
  setDelegateChoice,
  setDelegateReferral,
} from "./pages/ENSConstitution/delegateHelpers";
import HifiSummary from "./pages/HifiSummary";
import HifiTokenSwap from "./pages/HifiTokenSwap";
import HifiTokenApprove from "./pages/HifiTokenApprove";
import EnteryourDelegate from "./pages/EnteryourDelegate";
import HomeSwap from "./pages/HomeSwap";
import { useGetDelegates } from "./utils/hooks";
import { useQueryString } from "./utils/hooks";
// import { hasSwapped } from "./utils/token";
import { initWeb3, initWeb3Read } from "./web3modal";
import { COLORS } from "./styles";

import { i18n } from "@lingui/core";
import { I18nProvider } from "@lingui/react";
import { detect, fromNavigator } from "@lingui/detect-locale";
import { messages as enMessages } from "./locales/en-US";
import { messages as koMessages } from "./locales/ko-KR";
import HomeDelegate from "./pages/HomeDelegate";
import HifiDelegateSuccess from "./pages/HifiDelegateSuccess";
import { MobileNav } from "./components/MobileNav";
import { Footer } from "./components/GovernanceFooter";
import { MEDIA_SIZES } from "./styles";

i18n.load({
  en: enMessages,
  ko: koMessages,
});
const locale = detect(fromNavigator(), "en").split("-")[0];
i18n.activate(locale);

const FirstGlow = styled.div`
  background-image: url(${gradientHalo});
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: -37rem;
  background-position-x: 37rem;
  z-index: -1000;
  position: absolute;
  max-width: 88rem;
  width: 100%;
  height: 100vh;
  top: 0;
  right: 0;

  @media only screen and (${MEDIA_SIZES.max.phoneL}) {
    background-position-y: -13rem;
    background-position-x: 0rem;
  }
`;

const AppContainer = styled.div`
  margin: auto;
  box-sizing: border-box;
  overflow-x: hidden;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const AppContainerOuter = styled.div`
  height: calc(100vh - 150px);
  display: flex;
  flex-direction: column;
`;

const AppContainerMid = styled.div`
  flex: 1 0 auto;
  align-items: center;
`;

const FooterContainer = styled.div`
  width: 100%;
  background: ${COLORS.GREY};
`;

const useInit = () => {
  useEffect(() => {
    initWeb3Read();
  }, []);
};

const PRIVATE_ROUTE_QUERY = gql`
  query privateRouteQuery @client {
    addressDetails
    address
    isConnected
  }
`;

function PrivateRoute({ component: Component, type = "mainnet", ...rest }) {
  const { data } = useQuery(PRIVATE_ROUTE_QUERY);
  const history = useHistory();

  useEffect(() => {
    let finalAddressDetails = data.addressDetails;
    const run = async () => {
      try {
        if (
          finalAddressDetails.eligible !== undefined &&
          !finalAddressDetails.eligible
        ) {
          history.push("/dashboard");
          return;
        }
        // const isClaimed = await hasSwapped(data.address, type);
        // if (isClaimed) {
        //   history.push("/dashboard");
        // }
      } catch (error) {
        console.error("Private Route error: ", error);
        history.push("/dashboard");
      }
    };

    if (
      data.isConnected &&
      data.address &&
      data.addressDetails.eligible !== undefined
    ) {
      run();
    }

    if (!data.address && data.isConnected) {
      history.push("/");
    }
  }, [data.address, data.isConnected, data.addressDetails]);

  return <Route {...rest} render={(props) => <Component {...props} />} />;
}

function ConnectedRoute({ component: Component, ...rest }) {
  const { data } = useQuery(PRIVATE_ROUTE_QUERY);
  const history = useHistory();
  useEffect(() => {
    function run() {
      try {
        if (!data.address) {
          history.push("/");
        }
      } catch (error) {
        console.error("Connected Route error: ", error);
        history.push("/dashboard");
      }
    }

    if (data.isConnected && data.address) {
      run();
    }
  }, [data.address, data.isConnected]);
  return <Route {...rest} render={(props) => <Component {...props} />} />;
}

function App() {
  const query = useQueryString();
  const {
    data: { address, isConnected },
  } = useQuery(gql`
    query getAddress @client {
      address
      isConnected
    }
  `);
  useEffect(() => {
    const delegate = query.get("delegate");
    if (delegate && address) {
      setDelegateChoice(address, delegate);
      setDelegateReferral(delegate);
    }
  }, [address]);

  useEffect(() => {
    if (address) {
      initWeb3(false);
    }
  });

  useGetDelegates(isConnected);

  const [hamburgerOn, setHamburgerOn] = useState(false);
  const collapseHamburger = () =>
    hamburgerOn && setTimeout(() => setHamburgerOn(false), 100);
  const toggleHamburgerOn = () => setHamburgerOn(!hamburgerOn);

  let matchDelegate = useRouteMatch("/delegate");

  return (
    <I18nProvider i18n={i18n}>
      <Header hamburgerOn={hamburgerOn} toggleHamburgerOn={toggleHamburgerOn} />
      <MobileNav
        hamburgerOn={hamburgerOn}
        collapseHamburger={collapseHamburger}
        toggleHamburgerOn={toggleHamburgerOn}
      />

      <AppContainerOuter>
        <FirstGlow />
        <AppContainerMid>
          <AppContainer>
            <Switch>
              <PrivateRoute path="/delegates" component={ChooseYourDelegate} />
              <PrivateRoute
                path="/manual-delegates"
                component={EnteryourDelegate}
              />
              <ConnectedRoute
                path="/manual-delegates-no-swap"
                component={EnteryourDelegate}
              />
              <ConnectedRoute
                path="/delegate-tokens"
                component={DelegateTokens}
              />
              <ConnectedRoute
                path="/delegate-tokens-no-swap"
                component={DelegateTokens}
              />
              <PrivateRoute
                path="/summary/approve"
                component={HifiTokenApprove}
              />
              <PrivateRoute path="/summary/swap" component={HifiTokenSwap} />
              <PrivateRoute path="/summary" component={HifiSummary} />
              <PrivateRoute path="/swap-success" component={HifiSwapSuccess} />
              <PrivateRoute
                path="/delegate-success"
                component={HifiDelegateSuccess}
              />
              <PrivateRoute path="/dashboard" component={Dashboard} />
              <Route path="/choose-a-delegate">
                <DelegateRanking />
              </Route>
              <Route path="/delegate">
                <HomeDelegate />
              </Route>
              <PrivateRoute path="/delegation" component={Delegation} />
              <Route path="/swap">
                <HomeSwap />
              </Route>
              <Route path="/" render={() => <Redirect to="swap" />} />
            </Switch>
          </AppContainer>
        </AppContainerMid>
        {!matchDelegate && <SharedFooter />}
        {matchDelegate && (
          <FooterContainer>
            <Footer isReleased />
          </FooterContainer>
        )}
      </AppContainerOuter>
    </I18nProvider>
  );
}

function Index() {
  useInit();
  return (
    <Router>
      <App />
    </Router>
  );
}

export default Index;
