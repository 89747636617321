import React from "react";
import { useRouteMatch } from "react-router-dom";
import styled from "styled-components/macro";
import { gql } from "graphql-tag";
import { useQuery } from "@apollo/client";

import { Profile } from "./Profile";
import { largerThan } from "../utils/styledComponents";

import { ReactComponent as HeaderHifiLogo } from "../assets/imgs/HeaderHifiLogo.svg";
import { ReactComponent as DefaultYellowWarning } from "../assets/imgs/YellowWarning.svg";
import { Link } from "react-router-dom";

import { CTAButton } from "./buttons";
import { selectedDelegateReactive } from "../apollo";
import { Trans } from "@lingui/macro";
import logo from "../assets/imgs/logo.svg";
import { Hamburger } from "./Hamburger";
import { NavLink } from "./text";

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 40px;
  margin: auto;
  margin-top: 50px;
  margin-bottom: 60px;
  max-width: 1230px;
  padding: 0 24px;

  @media only screen and (max-width: 900px) {
    margin-top: 28px;
    padding: 0 16px;
    margin-bottom: 30px;
  }
`;

const LeftContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  ${largerThan.tablet`
    flex-direction: row;
  `}
`;

const RightContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const RightInnerContainer = styled.div`
  display: flex;
  align-items: center;

  @media only screen and (max-width: 900px) {
    display: none;
  }
`;

const WrappedLogo = styled(HeaderHifiLogo)`
  margin-bottom: 10px;
  margin-left: -20px;
`;

const NetworkWarningContainer = styled("div")`
  padding: 2px 15px;
  border: 1px solid rgb(54, 54, 54);
  border-radius: 30px;
  color: #ffffff;
  display: flex;
  height: 50px;
  align-items: center;
  max-width: 500px;
  width: fit-content;
  margin: auto;
`;

const YellowWarning = styled(DefaultYellowWarning)`
  width: 20px;
  flex-shrink: 0;
  margin-right: 15px;
`;

const NetworkWarning = function () {
  return (
    <NetworkWarningContainer>
      <YellowWarning />
      <Trans>Please change your network to Ethereum Mainnet</Trans>
    </NetworkWarningContainer>
  );
};

const LinkImage = styled.img`
  display: flex;

  @media only screen and (max-width: 900px) {
    width: 82px;
  }
`;

const HamburgerContainer = styled.div`
  display: none;
  @media only screen and (max-width: 900px) {
    display: block;
  }
  background-color: rgba(0, 0, 0, 0.3);
  padding: 4px;
  margin-right: -4px;
  margin-left: 24px;
  width: 30px;
  height: 18px;
  border-radius: 3px;
`;

const NetworkWarningOuterContainer = styled.div`
  padding: 0 16px;
`;

export const LaunchButton = styled.a`
  border: 1px solid white;
  font-size: 16px;
  border-radius: 10em;
  padding: 8px 16px;
  margin-left: 21px;
  font-weight: 600;

  color: white;
  text-decoration: none;
  &:hover {
    color: #794df6;
  }

  &:hover {
    border-color: #794df6;
  }

  @media only screen and (max-width: 900px) {
    display: none;
  }
`;

const Header = ({ toggleHamburgerOn, hamburgerOn }) => {
  const {
    data: { isConnected, address, addressDetails, network },
  } = useQuery(gql`
    query getHeaderData @client {
      address
      addressDetails
      isConnected
      network
    }
  `);

  let matchDelegate =
    useRouteMatch("/delegate") || useRouteMatch("/choose-a-delegate");
  let matchDel = useRouteMatch("/delegate");
  let matchSwap = useRouteMatch("/swap");

  return (
    <>
      <HeaderContainer>
        <LeftContainer>
          <a href="https://hifi.finance">
            <LinkImage src={logo} />
          </a>
        </LeftContainer>
        <RightContainer>
          <RightInnerContainer>
            <a href="https://metrics.hifi.finance">
              <NavLink>
                <Trans>Metrics</Trans>
              </NavLink>
            </a>
            <Link
              to="/delegate"
              onClick={() =>
                selectedDelegateReactive({ name: null, address: null })
              }
            >
              <NavLink current={matchDelegate}>
                <Trans>Governance</Trans>
              </NavLink>
            </Link>
            <a href="https://hifi.finance/roadmap">
              <NavLink>
                <Trans>Roadmap</Trans>
              </NavLink>
            </a>
            <a target="_blank" href="https://blog.hifi.finance">
              <NavLink>
                <Trans>Blog</Trans>
              </NavLink>
            </a>
          </RightInnerContainer>

          {!matchDel && (
            <Profile
              {...{ address }}
              size="medium"
              hasDropdown={true}
              isConnected={isConnected}
            />
          )}

          {matchDel && (
            <LaunchButton
              href="https://app.hifi.finance"
              className="link-normal link-launch2"
              target="_blank"
              rel="noreferrer"
            >
              <Trans>Launch App</Trans>
            </LaunchButton>
          )}

          <HamburgerContainer>
            <Hamburger active={hamburgerOn} onClick={toggleHamburgerOn} />
          </HamburgerContainer>
        </RightContainer>
      </HeaderContainer>
      <NetworkWarningOuterContainer>
        {network !== null && network !== 1 && <NetworkWarning />}
      </NetworkWarningOuterContainer>
    </>
  );
};

export default Header;
