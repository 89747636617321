import React, { useCallback, useEffect, useRef, useState } from "react";
import PurpleIconURI from "../assets/imgs/PurpleIcon.svg";
import { initWeb3 } from "../web3modal";
import { Trans } from "@lingui/macro";
import { i18n } from "@lingui/core";
import Jazzicon, { jsNumberForAddress } from "react-jazzicon";
import styled from "styled-components";
import { utils } from "ethers";
import { getEthersProvider, getMainnetEthersProvider } from "../web3modal";
import { imageUrl, shortenAddress, numberWithCommas } from "../utils/utils";
import GradientAvatar from "../assets/imgs/Gradient.svg";
import { ReactComponent as ProfileArrow } from "../assets/imgs/ProfileArrow.svg";
import { disconnect } from "../web3modal";
import DropdownWrapper from "./Dropdown";
import NullIcon from "../assets/imgs/NullIcon.svg";

const ConnectButtonContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  padding: 10px 16px;
  z-index: 10;
  background-color: #363636;
  border-radius: 64px;
  cursor: pointer;

  &:hover {
    background-color: #626262;
  }

  @media only screen and (max-width: 400px) {
    padding: 6px 10px;
  }
`;

const ConnectButtonText = styled.span`
  font-size: 16px;
  line-height: 16px;
  color: white;
  font-weight: 500;

  @media only screen and (max-width: 400px) {
    font-size: 12px;
  }
`;

const ImageContainer = styled.div`
  margin-left: 10px;
  display: flex;
`;

const ProfileContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  padding: 8px 16px 8px 10px;
  z-index: 10;
  background: #363636;
  box-shadow: ${(p) =>
    p.large ? "initial" : "0px 1px 20px rgba(0, 0, 0, 0.05)"};
  ${(p) => {
    switch (p.size) {
      case "large":
        return `
          background: inherit;
          max-width: 350px;
        `;
      case "medium":
        return `
        background: #363636;
        max-width: 220px;
      `;
      case "small":
        return `
        background: transparent;
        // max-width: 200px;
        width: 100%;
        padding: 0;
        box-shadow: none;
      `;
    }
  }}

  ${(p) =>
    p.hasDropdown &&
    `
    transition: all 0.2s ease-out;
    cursor: pointer;
  `}

  ${(p) =>
    p.dropdownOpen &&
    `
    box-shadow: none;
    background: #626262; 
  `}

  border-radius: 64px;
`;

const RightContainer = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 112px;

  div {
    overflow: hidden;
  }
`;

export const AvatarImg = styled.div`
  border-radius: 50%;
  background: ${(p) => (p.src ? `url(${p.src})` : `url(${NullIcon})`)};
  background-color: ${(p) => (p.src ? "black" : "none")};;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin-right: 10px;

  ${(p) => {
    switch (p.size) {
      case "large":
        return `
          width: 60px;
          height: 60px;
          min-width: 60px;
          min-height: 60px
      `;
      case "medium":
        return `
          width: 65px;
          height: 65px;
          min-width: 65px;
          min-height: 65px;
      `;
      case "small":
        return `
          width: 50px;
          height: 50px;
          min-width: 50px;
          min-height: 50px
      `;
      case "tiny":
        return `
          width:18px;
          height:18px;
          min-width:18px;
          min-height:18px;
          margin-right: 0;
      `;
      case "teeny":
        return `
          width:12px;
          height:12px;
          min-width:12px;
          min-height:12px;
          margin-right: 0;
      `;

      default:
        return `
          width: 50px;
          height: 50px;
          min-width: 50px;
          min-height: 50px
      `;
    }
  }};
`;

const EmptyAvatar = styled.div`
  border-radius: 50%;
  min-width: 50px;
  height: 50px;
  width: ${(p) => p.size === "large" && "60px"};
  height: ${(p) => p.size === "large" && "60px"};
  margin-right: 10px;
  background: #9f71ff;
`;

const EnsNameText = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: ${(p) => (p.size === "large" ? "26px" : "15px")};
  line-height: ${(p) => (p.size === "large" ? "36px" : "23px")};
  letter-spacing: -0.01em;
  margin-top: -2px;
  padding-bottom: 3px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #ffffff;
`;

const EnsNameTextNone = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: ${(p) => (p.size === "large" ? "26px" : "15px")};
  line-height: ${(p) => (p.size === "large" ? "36px" : "23px")};
  letter-spacing: -0.01em;
  margin-top: -2px;
  padding-bottom: 3px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #989898;
`;

const NoNameText = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 21px;
  display: flex;
  align-items: center;
  letter-spacing: -0.01em;

  color: #ffffff;
`;

const AddressText = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: #ffffff;

  ${(p) =>
    p.size === "large" &&
    `
      color: #989898;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      color: #666666;
      opacity: 0.67;
      `}

  ${(p) =>
    p.size === "large" &&
    !p.ensName &&
    `
        font-style: normal;
        font-weight: bold;
        font-size: 26px;
        line-height: 33px;
        color: #000000;
      `}
`;

const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return windowSize;
};

const useAddressCopied = () => {
  const [addressedCopied, _setAddressCopied] = useState(false);

  useEffect(() => {
    if (addressedCopied) {
      const timeout = setTimeout(() => _setAddressCopied(false), 2000);
      return () => clearTimeout(timeout);
    }
  }, [addressedCopied]);

  const setAddressCopied = useCallback((copyStr) => {
    navigator.clipboard.writeText(copyStr);
    _setAddressCopied(true);
  }, []);

  return { addressedCopied, setAddressCopied };
};

// function prop needed to stop hasOpened being passed to SVG
const StyledProfileArrow = styled(ProfileArrow)(({ hasOpened }) => ({
  transform: hasOpened ? "rotate(0deg)" : "rotate(180deg)",
  opacity: hasOpened ? 1 : 0.3,
  width: "12px",
  marginLeft: "12px",
  transition: "all 0.2s ease-out",
}));

const Gradient = styled.div`
  background-image: url(${PurpleIconURI});
  background-size: cover;
  width: ${(p) => (p.large ? "60px" : "50px")};
  height: ${(p) => (p.large ? "60px" : "50px")};
  border-radius: 50%;
  margin-right: 10px;
`;

export const Profile = ({ address, isConnected }) => {
  const [profileDetails, setProfileDetails] = useState({});
  const [navOpen, setNavOpen] = useState(false);
  const navSizeRef = useRef(null);
  let isAddress;
  try {
    isAddress = utils.getAddress(address);
  } catch {
    isAddress = false;
  }

  const windowSize = useWindowSize();
  const { addressedCopied, setAddressCopied } = useAddressCopied();

  useEffect(() => {
    const run = async () => {
      const ethersProvider = getEthersProvider();

      if (!ethersProvider) {
        console.error("no ethers provider");
        return;
      }

      const networkId = await ethersProvider.getNetwork();
      let ensName;
      if (!isAddress) {
        ensName = address;
      } else {
        ensName = await getMainnetEthersProvider().lookupAddress(address);
      }
      if (!ensName) {
        setProfileDetails({});
        return;
      }

      const resolver = await getMainnetEthersProvider().getResolver(ensName);
      const avatar = await resolver.getText("avatar");

      setProfileDetails({
        ensName,
        avatar,
        networkId,
      });
    };

    run().catch((e) => {
      console.error(e);
    });
  }, [address]);

  return (
    <DropdownWrapper
      dropdownItems={[
        { name: i18n._("Disconnect"), type: "deny", action: disconnect },
      ].concat(
        address
          ? [
              {
                name: i18n._("Copy Address"),
                action: () => {
                  address && setAddressCopied(address);
                },
              },
            ]
          : []
      )}
      isOpen={navOpen}
      setIsOpen={setNavOpen}
      testId="profile-dropdown"
    >
      <ConnectButtonContainer
        dropdownOpen={navOpen}
        onClick={() => {
          if (isConnected && !!address) {
            setNavOpen(!navOpen);
          } else {
            initWeb3();
          }
        }}
      >
        {!!profileDetails.ensName && isConnected && (
          <ConnectButtonText>{profileDetails.ensName}</ConnectButtonText>
        )}
        {!profileDetails.ensName && isAddress && !!address && isConnected && (
          <ConnectButtonText>
            {shortenAddress(address, 8, 6, 4)}
          </ConnectButtonText>
        )}
        {(!isConnected || !address) && (
          <ConnectButtonText>
            <Trans>Connect Wallet</Trans>
          </ConnectButtonText>
        )}
        {isConnected && !!address && (
          <ImageContainer>
            {profileDetails.avatar ? (
              <AvatarImg
                size={windowSize.width > 400 ? "tiny" : "teeny"}
                src={imageUrl(
                  profileDetails.avatar,
                  profileDetails.ensName,
                  profileDetails.networkId
                )}
              />
            ) : (
              <Jazzicon
                diameter={windowSize.width > 400 ? 18 : 12}
                seed={jsNumberForAddress(address)}
              />
            )}
          </ImageContainer>
        )}
      </ConnectButtonContainer>
    </DropdownWrapper>
  );
};

export const DelegateProfile = ({ address, size, hasDropdown }) => {
  const [profileDetails, setProfileDetails] = useState({});
  const [navOpen, setNavOpen] = useState(false);
  const navSizeRef = useRef(null);
  let isAddress;
  try {
    isAddress = utils.getAddress(address);
  } catch {
    isAddress = false;
  }

  useEffect(() => {
    const run = async () => {
      const ethersProvider = getEthersProvider();

      if (!ethersProvider) {
        console.error("no ethers provider");
        return;
      }

      const networkId = await ethersProvider.getNetwork();
      let ensName;
      if (!isAddress) {
        ensName = address;
      } else {
        ensName = await getMainnetEthersProvider().lookupAddress(address);
      }
      if (!ensName) return;

      const resolver = await getMainnetEthersProvider().getResolver(ensName);
      const avatar = await resolver.getText("avatar");

      setProfileDetails({
        ensName,
        avatar,
        networkId,
      });
    };

    run().catch((e) => {
      console.error(e);
    });
  }, [address]);

  if (size === "small") {
    return (
      <ProfileContainer size={size}>
        {profileDetails.avatar ? (
          <AvatarImg
            {...{ size }}
            key={profileDetails.avatar}
            src={imageUrl(
              profileDetails.avatar,
              profileDetails.ensName,
              profileDetails.networkId
            )}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = GradientAvatar;
            }}
          />
        ) : (
          <AvatarImg size={size} />
        )}
        <RightContainer>
          {profileDetails.ensName ? (
            <EnsNameText {...{ size }}>{profileDetails.ensName}</EnsNameText>
          ) : (
            <EnsNameText {...{ size }}>{i18n._("None")}</EnsNameText>
          )}
          <AddressText
            {...{
              size,
              ensName: profileDetails.ensName,
            }}
          >
            {isAddress &&
              address &&
              shortenAddress(
                address,
                size === "large" ? 30 : 10,
                size === "large" ? 10 : 5,
                size === "large" ? 10 : 5
              )}
          </AddressText>
        </RightContainer>
      </ProfileContainer>
    );
  }

  return (
    <DropdownWrapper
      dropdownItems={[
        { name: i18n._("Dashboard"), link: "/dashboard" },
        { name: i18n._("Disconnect"), type: "deny", action: disconnect },
      ]}
      isOpen={navOpen}
      setIsOpen={setNavOpen}
      testId="profile-dropdown"
    >
      <ProfileContainer
        size={size}
        hasDropdown={hasDropdown}
        dropdownOpen={navOpen}
        onClick={() => setNavOpen(!navOpen)}
      >
        {profileDetails.avatar ? (
          <AvatarImg
            size={size}
            src={imageUrl(
              profileDetails.avatar,
              profileDetails.ensName,
              profileDetails.networkId
            )}
          />
        ) : (
          <EmptyAvatar size={size} />
        )}
        <RightContainer>
          {profileDetails.ensName ? (
            <EnsNameText size={size}>{profileDetails.ensName}</EnsNameText>
          ) : (
            <NoNameText>
              <Trans>No name set</Trans>
            </NoNameText>
          )}
          <AddressText size={size} ensName={profileDetails.ensName}>
            {isAddress &&
              address &&
              shortenAddress(
                address,
                size === "large" ? 30 : 10,
                size === "large" ? 10 : 5,
                size === "large" ? 10 : 5
              )}
          </AddressText>
        </RightContainer>
        {hasDropdown && (
          <StyledProfileArrow hasopened={navOpen ? "true" : "false"} />
        )}
      </ProfileContainer>
    </DropdownWrapper>
  );
};

const DelegateBoxVotes = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;

  display: flex;
  align-items: center;

  color: #989898;
`;

export const SelectedDelegateProfile = ({
  address,
  votes,
  hasDropdown,
  name,
  isSelf,
}) => {
  const [profileDetails, setProfileDetails] = useState({});
  const [navOpen, setNavOpen] = useState(false);
  const navSizeRef = useRef(null);
  let isAddress;
  try {
    isAddress = utils.getAddress(address);
  } catch {
    isAddress = false;
  }

  useEffect(() => {
    const run = async () => {
      const ethersProvider = getEthersProvider();

      if (!ethersProvider) {
        console.error("no ethers provider");
        return;
      }

      const networkId = await ethersProvider.getNetwork();
      let ensName;
      if (!isAddress) {
        ensName = address;
      } else {
        ensName = await getMainnetEthersProvider().lookupAddress(address);
      }
      ensName = ensName || name;
      if (!ensName) return;

      const resolver = await getMainnetEthersProvider().getResolver(ensName);
      const avatar = await resolver.getText("avatar");

      setProfileDetails({
        ensName,
        avatar,
        networkId,
      });
    };

    run().catch((e) => {
      console.error(e);
    });
  }, [address, name]);

  return (
    <ProfileContainer size={"small"}>
      {profileDetails.avatar ? (
        <AvatarImg
          key={profileDetails.avatar}
          src={imageUrl(
            profileDetails.avatar,
            profileDetails.ensName,
            profileDetails.networkId
          )}
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = GradientAvatar;
          }}
        />
      ) : isSelf ? (
        <Gradient />
      ) : (
        <AvatarImg size={"small"} />
      )}
      <RightContainer>
        {profileDetails.ensName ? (
          <EnsNameText>{profileDetails.ensName}</EnsNameText>
        ) : isSelf ? (
          <EnsNameText>{i18n._("Self Delegate")}</EnsNameText>
        ) : !!address ? (
          <EnsNameTextNone>{shortenAddress(address, 20, 10, 10)}</EnsNameTextNone>
        ) : (
          <EnsNameTextNone>{i18n._("None")}</EnsNameTextNone>
        )}
        <AddressText
          {...{
            ensName: profileDetails.ensName,
          }}
        >
          <DelegateBoxVotes>
            {votes > 0 ? `${numberWithCommas(votes)} votes` : "-----"}
          </DelegateBoxVotes>
        </AddressText>
      </RightContainer>
    </ProfileContainer>
  );
};
